import { request } from "lib/request";
import { Currency } from "lib/currency";
import { EventType } from "models/event";
import { PaymentProvider } from "models/payment";
import { ProgramPlan } from "models/plan";
import {
  QuestionnaireModel,
  QuestionnaireVersion,
  VERSION_KEY,
} from "models/questionnaire";
import { QuestionModel } from "models/question";
import { OptionModel } from "models/option";
import { getVersion } from "lib/questionnaire";
import { ConfigModel } from "models/config";
import { StateModel } from "models/state";
import { StudyScheduleModel } from "models/content/study-schedule";
import { read } from "lib/storage";
import { getQuestionDescription, getQuestionTitle } from "lib/question";

interface EventProps {
  uuid: string;
}

interface AttributesModel {
  eventType: EventType;

  // other parameters, these change per event type
  [key: string]: any;
}

async function eventRequest(data: AttributesModel) {
  try {
    await request({
      method: "POST",
      url: `/v1/send-event`,
      data,
    });
  } catch (err) {
    // nada
  }
}

// ---------------------------------------------------------

interface SendPaymentMethodSelectedEventProps extends EventProps {
  paymentId: string;
  productId: string;
  paymentProvider: PaymentProvider;
  currency: Currency;
  price: number;
  programLength: ProgramPlan;
  listPrice?: number;
}

export const sendPaymentMethodSelectedEvent = async (
  props: SendPaymentMethodSelectedEventProps,
) => {
  await eventRequest({
    eventType: "PaymentMethodSelected",
    paymentMethodSelected: { ...props },
  });
};

// ---------------------------------------------------------

interface SendGetQuestionnaireEventProps extends EventProps {
  questionnaireVersion: QuestionnaireVersion;
}

export const sendGetQuestionnaireEvent = async (
  props: SendGetQuestionnaireEventProps,
) => {
  await eventRequest({
    eventType: "GetQuestionnaire",
    getQuestionnaire: { ...props },
  });
};

// ---------------------------------------------------------

interface SendQuestionnaireAnswerEventProps extends EventProps {
  config: ConfigModel;
  state: StateModel;
  questionnaire: QuestionnaireModel;
  question: QuestionModel;
  option?: OptionModel;
}

export const sendQuestionnaireAnswerEvent = async (
  props: SendQuestionnaireAnswerEventProps,
) => {
  const { questionnaire, question, state, config, uuid, option } = props;
  const questionnaireAnswer: any = {
    uuid,
    questionnaireVersion: getVersion(questionnaire),
    question: {
      id: question.id,
      type: question.type,
      title: getQuestionTitle(questionnaire, question, state, config),
      description: getQuestionDescription(
        questionnaire,
        question,
        state,
        config,
      ),
    },
  };

  if (option) {
    questionnaireAnswer.option = {
      id: option.id,
      type: option.type || "",
      description: option.description || "",
    };
  }

  await eventRequest({ eventType: "QuestionnaireAnswer", questionnaireAnswer });
};

// ---------------------------------------------------------

interface SendQuestionnaireQuestionShownEventProps extends EventProps {
  questionnaire: QuestionnaireModel;
  question: QuestionModel;
}

export const sendQuestionnaireQuestionShownEvent = async (
  props: SendQuestionnaireQuestionShownEventProps,
) => {
  const { question, questionnaire, uuid } = props;
  const questionnaireQuestionShown: any = {
    uuid,
    questionnaireVersion: getVersion(questionnaire),
    questionId: question.id,
  };

  await eventRequest({
    eventType: "QuestionnaireQuestionShown",
    questionnaireQuestionShown,
  });
};

// ---------------------------------------------------------

interface SendVirtualQuestionnaireQuestionShownEventProps extends EventProps {
  questionId: number;
}

// this in meant for sending events from post purchase views
export const sendVirtualQuestionnaireQuestionShownEvent = async (
  props: SendVirtualQuestionnaireQuestionShownEventProps,
) => {
  const { uuid, questionId } = props;
  const questionnaireVersion = read<number>(VERSION_KEY) || 0;
  const questionnaireQuestionShown: any = {
    uuid,
    questionnaireVersion,
    questionId,
  };

  await eventRequest({
    eventType: "QuestionnaireQuestionShown",
    questionnaireQuestionShown,
  });
};

// ---------------------------------------------------------

interface SendStudyScheduleSelectedEventProps extends EventProps {
  studySchedule: StudyScheduleModel;
}

export const sendStudyScheduleSelectedEvent = async (
  props: SendStudyScheduleSelectedEventProps,
) => {
  const { uuid, studySchedule } = props;
  const { months, practiseDaysPerWeek, minutesPerSession } = studySchedule;

  const studyScheduleSelected: any = {
    uuid,
    programLength: `${months}M`,
    practiseDaysPerWeek,
    sessionLength: minutesPerSession,
  };

  await eventRequest({
    eventType: "StudyScheduleSelected",
    studyScheduleSelected,
  });
};

// ---------------------------------------------------------

interface QuestionnairePopupShownEventProps extends EventProps {
  questionnaire: QuestionnaireModel;
  popupId: string;
}

export const QuestionnairePopupShownEvent = async (
  props: QuestionnairePopupShownEventProps,
) => {
  const { uuid, questionnaire, popupId } = props;

  const questionnairePopupShown: any = {
    uuid,
    questionnaireVersion: getVersion(questionnaire),
    popupId,
  };

  await eventRequest({
    eventType: "QuestionnairePopupShown",
    questionnairePopupShown,
  });
};

// --------------------------------------------------------

interface CampaignEventProps extends EventProps {
  campaignId?: string;
  versionId?: string;
}

export const sendCampaignEvent = async (props: CampaignEventProps) => {
  const campaignEvent: any = { ...props };
  await eventRequest({
    eventType: "CampaignEvent",
    campaignEvent,
  });
};

// -------------------------------------------------------------

export const sendChatbotOpenEvent = async (uuid: string) => {
  const chatbotOpen = { uuid };

  await eventRequest({
    eventType: "ChatbotOpen",
    chatbotOpen,
  });
};
