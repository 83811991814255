import styled, { css } from "styled-components";
import devices from "theme/devices";
import { ColorCode } from "theme/colors";
import { ThemeProps } from "models/theme";

interface Props extends ThemeProps {
  $transparent: boolean;
}

export const StyledNavbar = styled.div<Props>`
  z-index: 99;

  background-color: ${ColorCode.LAYOUT_DARK};

  ${(props) =>
    props.$theme === "HablaIngles" &&
    css`
      background-color: #030c41;
    `}

  ${(props) =>
    props.$transparent &&
    css`
      background-color: transparent;
    `}

  padding: 1.125rem;
  height: 58px;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media ${devices.tablet} {
    padding: 2.75rem 2.5rem;
  }

  @media ${devices.desktop} {
    padding: 2.75rem 2.5rem;
  }
`;

export const StyledMobileNav = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
`;
