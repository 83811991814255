import { Device } from "theme/devices";
import { usePageLocale } from "./route";
import { MediaExtension } from "lib/media";
import { LocaleType } from "models/locale";

export const parseMediaUrl = (
  filename: string,
  device: Device,
  extension: MediaExtension,
  locale?: LocaleType,
) => {
  const parts = ["/media/", filename];

  // add size
  if (device !== "mobile") {
    parts.push(`-${device}`);
  }

  // add locale
  if (locale) {
    // we don't have english images
    if (locale === "en-US") {
      locale = "es-419";
    }
    parts.push("-");
    parts.push(locale);
  }

  // add extension
  parts.push(".");
  parts.push(extension);

  return parts.join("");
};

export const useMedia = (
  filename: string,
  device: Device,
  extension: MediaExtension,
  localised: boolean = false,
) => {
  const locale = usePageLocale();
  return parseMediaUrl(
    filename,
    device,
    extension,
    localised ? locale : undefined,
  );
};
