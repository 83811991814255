import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import StudyScheduleContent from "./study-schedule";

export const studyScheduleContentValidator: ValidatorMethod = (state) => {
  const { schedule } = state;
  return schedule === undefined ? "fail" : "pass";
};

export const studyScheduleSchema: ContentSchema = {
  Component: StudyScheduleContent,
  validator: studyScheduleContentValidator,
};
