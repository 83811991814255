import { MediaSchema } from "lib/media";
import { FunctionComponent } from "react";
import { ImageCSS, StyledLogo } from "./logo.styles";
import Image from "components/image";
import { useLink } from "hooks/route";

interface LogoProps {
  link?: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({ link = true }) => {
  const homeLink = useLink("home");
  const filename = `logos/LogoMarkWhite`;

  const schema: MediaSchema = { filename, extension: "svg" };

  return (
    <StyledLogo href={link ? homeLink : undefined}>
      <Image schema={schema} alt="Master English" css={ImageCSS} />
    </StyledLogo>
  );
};

export default Logo;
