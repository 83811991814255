import { Fragment, FunctionComponent } from "react";
import {
  StyledFrame,
  StyledLoader,
  StyledSpinner,
  StyledText,
} from "./frame.styles";
import { useTranslations } from "hooks/translations";
import { useView } from "hooks/view";

interface PaddleFrameProps {
  isLoading: boolean;
}

const PaddleFrame: FunctionComponent<PaddleFrameProps> = ({ isLoading }) => {
  const view = useView();
  const t = useTranslations();
  return (
    <StyledFrame $isLoading={isLoading} className="paddleFrame" $view={view}>
      {isLoading && (
        <Fragment>
          <StyledSpinner>
            <StyledLoader />
          </StyledSpinner>
          <StyledText>{t("purchase_loader")}</StyledText>
        </Fragment>
      )}
    </StyledFrame>
  );
};

export default PaddleFrame;
