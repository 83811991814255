import { Fragment, FunctionComponent, ReactNode, Suspense } from "react";
import SharedHead from "components/head";
import { StyledView, StyledViewWrapper } from "./view.styles";
import ViewProvider from "providers/view";
import { ViewType } from "models/view";
import { useTheme } from "hooks/theme";
import { lazy } from "react";
import { replaceTags, stripHtml } from "lib/string";

const LazyFooter = lazy(() => import("../footer/footer"));

interface ViewProps {
  children: ReactNode;
  title: string;
  description: string;
  image?: string;
  view?: ViewType;
  footer?: boolean;
  showLogoInFooter?: boolean;
  cover?: boolean;
}

const View: FunctionComponent<ViewProps> = ({
  children,
  title,
  description,
  image,
  view = "light",
  footer = false,
  showLogoInFooter = false,
  cover = false,
}) => {
  const theme = useTheme();

  // replace tags
  title = replaceTags(title, { FIRSTNAME: "..." });
  description = replaceTags(description, { FIRSTNAME: "..." });

  return (
    <Fragment>
      <SharedHead
        title={stripHtml(title)}
        description={stripHtml(description)}
        image={image}
      ></SharedHead>
      <ViewProvider type={view}>
        <StyledViewWrapper $cover={cover}>
          <StyledView $view={view} $theme={theme}>
            {children}
          </StyledView>
          {footer && (
            <Suspense fallback={<div />}>
              <LazyFooter logo={showLogoInFooter} />
            </Suspense>
          )}
        </StyledViewWrapper>
      </ViewProvider>
    </Fragment>
  );
};

export default View;
