import HelmetWrapper from "components/helmet";
import { gtmOneTrust } from "lib/gtm";
import { FunctionComponent, useEffect } from "react";

const OneTrust: FunctionComponent = () => {
  useEffect(() => {
    gtmOneTrust();
  }, []);

  // currently supported widget version is 202310.1.0
  // TODO: update to later template
  // TODO: enable google consent mode
  // TODO: categorize cookies
  return (
    <HelmetWrapper>
      <script
        type="text/javascript"
        src={import.meta.env.VITE_PUBLIC_ONETRUST_URL}
        data-document-language="true"
        data-domain-script={import.meta.env.VITE_PUBLIC_ONETRUST_DOMAIN}
      />
    </HelmetWrapper>
  );
};

export default OneTrust;
