import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentProps } from "../factory";
import MultiSelection from "components/form/multi-selection";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { SkillsToImproveContentType } from "models/content/skills-to-improve";

interface SkillsToImproveContentProps extends ContentProps {
  content: SkillsToImproveContentType;
}

const SkillsToImproveContent: FunctionComponent<
  SkillsToImproveContentProps
> = ({ content, onChange }) => {
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  const [selected, toggleSelected] = useState<number[]>([]);
  const { skillsToImproveOptions } = content;
  const { items = [] } = skillsToImproveOptions;

  useEffect(() => {
    // always start from clean state, do not reuse browser state
  }, []);

  // compose options for the multiselection
  const options = items.map((skill, id) => {
    return {
      id,
      text: skill,
    };
  });

  const handleChoose = (id: number) => {
    if (selected.includes(id)) {
      toggleSelected([...selected.filter((s) => s !== id)]);
      state.skillsToImprove = [
        ...(state.skillsToImprove || []).filter((sd) => sd !== id),
      ];
    } else {
      const newSelected = [...selected, id].sort();
      toggleSelected(newSelected);
      state.skillsToImprove = newSelected;
    }

    // persist
    updateQuestionnaireState(state);

    // signal
    onChange();
  };

  return (
    <Fragment>
      <MultiSelection
        selected={selected}
        options={options}
        onChoose={handleChoose}
      />
    </Fragment>
  );
};

export default SkillsToImproveContent;
