import { Fragment } from "react";
import {
  StyledNavigationLeft,
  StyledNavigationRight,
} from "./quick-navigation.styles";
import { useQuestion } from "hooks/question";
import { useLink } from "hooks/route";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router";
import { getInitialQuestion, getProgramView } from "lib/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { QuestionType } from "models/question";

const QuickNavigation = () => {
  const questionnaire = useQuestionnaire();
  const question = useQuestion();
  const link = useLink("question", { version: questionnaire.id });
  const navigate = useNavigate();
  const state = useQuestionnaireState();
  const initialView = getInitialQuestion(questionnaire);
  const programView = getProgramView(questionnaire, state);

  // ignore in prod
  if (import.meta.env.VITE_PUBLIC_NAME === "production") {
    return null;
  }

  const nav = (id: number) => {
    const newLink = link.replace(":question", id.toString());
    navigate(newLink);
  };

  const onLeft = () => {
    nav(question.id - 1);
  };

  const onRight = () => {
    nav(question.id + 1);
  };

  const blacklist: QuestionType[] = [
    "PreparingProgram",
    "ProgramView",
    "SignupView",
  ];
  const isBlacklisted = blacklist.includes(question.type);

  return (
    <Fragment>
      {question.id > initialView.id && (
        <StyledNavigationLeft onClick={onLeft}>⏴</StyledNavigationLeft>
      )}
      {question.id < programView.id && !isBlacklisted && (
        <StyledNavigationRight onClick={onRight}>⏵</StyledNavigationRight>
      )}
    </Fragment>
  );
};

export default QuickNavigation;
