import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import PriceContent from "./price";

export const priceContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const priceSchema: ContentSchema = {
  Component: PriceContent,
  validator: priceContentValidator,
};
