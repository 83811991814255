import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import AppStoreButtonContent from "./app-store-button";

export const appStoreButtonContentValidator: ValidatorMethod = () => {
    return "pass";
  };
  
  export const appStoreButtonSchema: ContentSchema = {
    Component: AppStoreButtonContent,
    validator: appStoreButtonContentValidator,
  };
  