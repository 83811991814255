import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import PaddleContent from "./paddle";

export const paddleContentValidator: ValidatorMethod = () => {
  return "block";
};

export const paddleSchema: ContentSchema = {
  Component: PaddleContent,
  validator: paddleContentValidator,
};
