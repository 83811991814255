import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import DescriptionContent from "./description";

export const descriptionContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const descriptionSchema: ContentSchema = {
  Component: DescriptionContent,
  validator: descriptionContentValidator,
};
