import { FunctionComponent, useEffect, useState } from "react";
import { frameStyle } from "./frame.styles";
import { ProductModel } from "models/product";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { write } from "lib/storage";
import { PAYMENT_RETRY_URL_KEY } from "models/payment";
import { getProgramView, getVersion } from "lib/questionnaire";
import { useDiscount } from "hooks/products";
import PaddleFrame from "./frame";
import { getPaddleInstance } from "@paddle/paddle-js";
import { createTransaction } from "services/transaction";
import {
  useStaticLink,
  useQuestionLink,
  useFullUrl,
  usePaddleLocale,
} from "hooks/route";

interface PaddleBillingProps {
  product: ProductModel;
}

const PaddleBillingFrame: FunctionComponent<PaddleBillingProps> = ({
  product,
}) => {
  const discount = useDiscount();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const [isLoading, toggleIsLoading] = useState(true);
  const version = getVersion(questionnaire);
  const pv = getProgramView(questionnaire, state);
  const returnPath = useStaticLink(version, "post-purchase");
  const programViewUrl = useQuestionLink(version, pv.id);
  const successUrl = useFullUrl(returnPath);
  const paddleLocale = usePaddleLocale();

  const initPaddle = (transactionId: string) => {
    // mark loaded, maybe there is an event callback for this?
    toggleIsLoading(true);

    // open paddle iframe
    const Paddle = getPaddleInstance();
    if (Paddle) {
      Paddle.Checkout.open({
        // checkout settings
        settings: {
          displayMode: "inline",
          locale: paddleLocale,
          frameTarget: "paddleFrame",
          frameStyle,
          successUrl,
        },

        // send some customer data just in case
        customer: {
          email: state.user.email,
        },

        // transaction id
        transactionId,
      });
    }
  };

  useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;

    const init = async () => {
      // fetch paylink
      const transactionId = await createTransaction({
        couponCode: discount,
        pricingId: product.productId,
      });

      // also, create and store retry path
      write(PAYMENT_RETRY_URL_KEY, programViewUrl);

      // init paddle
      setTimeout(() => {
        initPaddle(transactionId);
      }, 2500);

      // force remove spinner after 15sec
      spinnerTimeout = setTimeout(() => {
        toggleIsLoading(false);
      }, 15000);
    };

    init();

    // create an interval to watch if we need to cancel the payment
    return () => {
      clearTimeout(spinnerTimeout);
    };
  }, [product.productId]);

  return <PaddleFrame isLoading={isLoading} />;
};

export default PaddleBillingFrame;
