import {
  passwordValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { ContentSchema } from "../factory";
import SignupPasswordContent from "./signup-password";
import { ValidatorMethod } from "../../validations";

export const signupPasswordContentValidator: ValidatorMethod = (state, t) => {
  const { user } = state;
  const { password = "" } = user;

  // create validation
  const validators: ValidatorFn[] = [
    requiredValidator(t),
    passwordValidator(t),
  ];
  const validationResult = runValidations(password, validators);

  if (validationResult) {
    return "fail";
  }

  return "pass";
};

export const signupPasswordSchema: ContentSchema = {
  Component: SignupPasswordContent,
  validator: signupPasswordContentValidator,
};

export const generateRandomPassword = () => "demodemo";
