import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import PreparingProgramContent from "./preparing-program";

export const preparingProgramContentValidator: ValidatorMethod = () => {
  // always block, use onSubmit mechanism to trigger nav
  return "block";
};

export const preparingProgramSchema: ContentSchema = {
  Component: PreparingProgramContent,
  validator: preparingProgramContentValidator,
};
