import { ConfigModel } from "models/config";
import { ProgramPlan } from "models/plan";
import { ProductModel } from "models/product";
import { sendPaymentMethodSelectedEvent } from "services/event";

// some event listeners
export const handleCheckoutPaymentSelectedEvent = (
  config: ConfigModel,
  programLength: ProgramPlan,
  product: ProductModel
) => {
  const { currency, price, listPrice, productId, paymentProvider } = product;
  sendPaymentMethodSelectedEvent({
    uuid: config.uuid,
    paymentId: "", // not used with paddle
    productId,
    currency,
    price,
    listPrice,
    programLength,
    paymentProvider,
  });
};
