import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import BubbleContent from "./bubble";

export const imageContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const bubbleSchema: ContentSchema = {
  Component: BubbleContent,
  validator: imageContentValidator,
};
