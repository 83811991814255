import Image from "components/image";
import { MediaExtension, MediaSchema } from "lib/media";
import { FunctionComponent } from "react";
import { ImageCSSLeft, ImageCSSRight } from "./background.styles";
import { ImagePriorityType } from "components/list.styles";

interface BackgroundProps {
  filename: string;
  align: "left" | "right";
  extension?: MediaExtension;

  // img priority
  priority?: ImagePriorityType;
}

const Background: FunctionComponent<BackgroundProps> = ({
  filename,
  align,
  extension = "png",
  priority,
}) => {
  const schema: MediaSchema = {
    filename,
    extension,
    tablet: true,
    desktop: true,
  };

  return (
    <Image
      schema={schema}
      alt=""
      css={align === "left" ? ImageCSSLeft : ImageCSSRight}
      priority={priority}
    />
  );
};

export default Background;
