import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import TestimonialContent from "./testimonial";

export const testimonialContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const testimonialSchema: ContentSchema = {
  Component: TestimonialContent,
  validator: testimonialContentValidator,
};
