import Description from "components/description";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useTranslations } from "hooks/translations";
import { getPlatform, PlatformType } from "lib/platform";
import { getStoreUrl } from "lib/store";
import { createLinkTag } from "lib/string";
import { FunctionComponent, useEffect, useState } from "react";

const AppLinkView: FunctionComponent = () => {
  const t = useTranslations();
  const [platformUrl, togglePlatformUrl] = useState("");
  const [storeUrl, toggleStoreUrl] = useState("");

  const getPlatformUrl = () => {
    const { href, pathname } = window.location;

    const platform = getPlatform();
    if (platform === PlatformType.ANDROID) {
      return `kosmo://${pathname}`;
    } else {
      return href.replace("https:", "http:");
    }
  };

  useEffect(() => {
    const url = getPlatformUrl();
    togglePlatformUrl(url);

    const storeUrl = getStoreUrl();
    toggleStoreUrl(storeUrl);
  }, []);

  const body = t("login_button_page_body").replace(
    "descarga la app",
    createLinkTag(storeUrl, t("login_button_page_link")),
  );

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("login_button_page_button"),
        type: "primary",
        onClick: () => {
          window.open(platformUrl);
        },
        href: platformUrl,
      },
    },
  ];

  return (
    <View
      title={t("login_button_page_title")}
      description=""
      footer
      showLogoInFooter
      cover
    >
      <Navbar />
      <ContentContainer>
        <Title>{t("login_button_page_title")}</Title>
        <Description>{body}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default AppLinkView;
