import { MediaSchema } from "lib/media";
import { HeaderModel } from "models/question";
import { css } from "styled-components";
import devices from "theme/devices";

export const ImageCSS = css`
  width: 100%;
  height: 200px;

  object-fit: cover;
  object-position: center top;

  @media ${devices.tablet} {
    height: 350px;
  }

  @media ${devices.desktop} {
    height: 510px;
    object-position: center 20%;
  }
`;

export const ImageCSSFlexible = css`
  width: 100%;
`;

export const parseHeaderMediaSchema = (header: HeaderModel) => {
  const { extension = "jpg" } = header;
  const filename = `header/${header.filename}`;

  const schema: MediaSchema = { filename, extension, tablet: true };
  return schema;
};
