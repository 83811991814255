import HelmetWrapper from "components/helmet";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { handleCheckoutPaymentSelectedEvent } from "lib/paddle";
import { getProgramPlan } from "lib/study-schedule";
import { PaddleEventType } from "models/paddle";
import { ProductModel } from "models/product";
import { FunctionComponent, useEffect } from "react";

const isSandbox = import.meta.env.VITE_PUBLIC_PADDLE_SANDBOX === "true";
const vendor = parseInt(import.meta.env.VITE_PUBLIC_PADDLE_VENDOR_ID);

interface PaddleClassicSetupProps {
  product: ProductModel;
  onLoad: () => void;
}

const PaddleClassicSetup: FunctionComponent<PaddleClassicSetupProps> = ({
  onLoad,
  product,
}) => {
  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const programPlan = getProgramPlan(questionnaire, state);

  useEffect(() => {
    const interval = setInterval(() => {
      const Paddle = (window as any).Paddle;
      const exists = Paddle !== undefined;

      if (exists) {
        // paddle found, cancel interval
        clearInterval(interval);

        // set sandbox
        if (isSandbox) {
          Paddle.Environment.set("sandbox");
        }

        Paddle.Setup({
          vendor,
          eventCallback: function (data: any) {
            // Paddle react component will add delegatePaddleEvent method to handle events in a proper scope
            switch (data.event) {
              case PaddleEventType.CheckoutPaymentSelected:
                handleCheckoutPaymentSelectedEvent(
                  config,
                  programPlan,
                  product,
                );
                break;
            }
          },
        });

        // signal parent
        onLoad();
      }
    }, 100);

    // clear timeout on unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <HelmetWrapper>
      <script
        type="text/javascript"
        src="https://cdn.paddle.com/paddle/paddle.js"
      />
    </HelmetWrapper>
  );
};

export default PaddleClassicSetup;
