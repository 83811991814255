import Image from "components/image";
import styled from "styled-components";
import devices from "theme/devices";

export const StyledLocaleMobile = styled.div`
  display: block;
  user-select: none;

  @media ${devices.desktop} {
    display: none;
  }
`;

export const StyledImage = styled(Image)`
  height: 20px;
  pointer-events: none;
  cursor: pointer;
`;

export const StyledSelect = styled.select`
  position: absolute;
  opacity: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  position: fixed;
  top: -150px;
  opacity: 0;
`;
