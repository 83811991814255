import { useConfig } from "hooks/config";
import { usePageLocale } from "hooks/route";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { useUpdateTheme } from "hooks/theme";
import { getVersion } from "lib/questionnaire";
import { syncState } from "lib/state";
import { read, write } from "lib/storage";
import {
  QuestionnaireModel,
  QuestionnaireVersion,
  QUESTIONNAIRE_KEY,
  VERSION_KEY,
  areJsonHot,
} from "models/questionnaire";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router";
import { loadCleanQuestionnaire } from "services/questionnaire";

interface QuestionnaireContextInterface {
  questionnaire: QuestionnaireModel;
  updateQuestionnaire: (newQuestionnaire: QuestionnaireModel) => void;
}

// create context
export const QuestionnaireContext = createContext<
  QuestionnaireContextInterface | undefined
>(undefined);

interface QuestionnaireProviderProps {
  children: ReactNode;
  version: QuestionnaireVersion;
  questionId: number;
}

const getCachedQuestionnaire = () => {
  return read<QuestionnaireModel>(QUESTIONNAIRE_KEY) || undefined;
};

const QuestionnaireProvider: FunctionComponent<QuestionnaireProviderProps> = ({
  children,
  version,
  questionId,
}) => {
  const [questionnaire, toggleQuestionnaire] = useState<
    QuestionnaireModel | undefined
  >(undefined);
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const [loaded, toggleLoaded] = useState(false);
  const state = useQuestionnaireState();
  const config = useConfig();
  const updateTheme = useUpdateTheme();
  const { uuid } = config;
  const [searchParams] = useSearchParams();
  const locale = usePageLocale();

  const updateQuestionnaire = (newQuestionnaire: QuestionnaireModel) => {
    // change local state
    toggleQuestionnaire(newQuestionnaire);
    console.info(`Updated Questionnaire Context`);

    // store version info
    write<QuestionnaireVersion>(VERSION_KEY, getVersion(newQuestionnaire));
    write<QuestionnaireModel>(QUESTIONNAIRE_KEY, newQuestionnaire);
  };

  useEffect(() => {
    const fn = async () => {
      console.info(`QuestionnaireProvider, v=${version} and q=${questionId}`);

      if (!questionnaire || getVersion(questionnaire) !== version) {
        console.info(` ==> Context is empty, trying to load...`);

        // first try from cache
        let loaded = getCachedQuestionnaire();
        if (loaded && getVersion(loaded) === version) {
          // just log here
          console.info(` ==> Loaded from localStorage.`);
        }

        // if json are hot, ignore cache
        if (areJsonHot()) {
          loaded = undefined;
          console.info(` ==> Used version is "hot", ignore localStorage.`);
        }

        // if we don't have a version in cache or it is wrong version, then we need to fetch it again
        if (!loaded || getVersion(loaded) !== version) {
          loaded = await loadCleanQuestionnaire(uuid, version, true, locale);
        }

        // force update theme, if needed
        const theme = searchParams.get("theme");
        if (loaded.theme !== undefined && !theme) {
          console.info(`force theme ${loaded.theme}`);
          updateTheme(loaded.theme);
        }

        // toggle
        updateQuestionnaire(loaded);
      }

      // persist to state
      console.info(` ==> Switched to question ${questionId}`);
      syncState(state, version, questionId);
      updateQuestionnaireState(state);

      // mark loaded
      toggleLoaded(true);
    };
    fn();
  }, [questionId]); // make sure the questionId changes the state!

  if (!questionnaire || !loaded) {
    return null;
  }

  return (
    <QuestionnaireContext.Provider
      value={{
        questionnaire,
        updateQuestionnaire,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireProvider;
