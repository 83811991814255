import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ScheduleSummaryContent from "./schedule-summary";

export const testimonialContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const scheduleSummarySchema: ContentSchema = {
  Component: ScheduleSummaryContent,
  validator: testimonialContentValidator,
};
