import List, { ListItemProps } from "components/list";
import { useTranslations } from "hooks/translations";
import { replaceTags } from "lib/string";
import { TagMap } from "lib/tag";
import { ParagraphModel } from "models/content/paragraph";
import { FunctionComponent } from "react";

interface BulletListProps {
  paragraphs: ParagraphModel[];
  tags: TagMap;
  min?: number;
  max?: number;
}

const BulletList: FunctionComponent<BulletListProps> = ({
  paragraphs,
  min,
  max,
  tags,
}) => {
  const t = useTranslations();

  // first filter out the range
  const filteredParagraphs = paragraphs.filter((pg) => {
    if (min !== undefined && pg.id < min) {
      return false;
    } else if (max !== undefined && pg.id > max) {
      return false;
    }
    return true;
  });

  // create list items
  const items = filteredParagraphs.map((pg) => {
    const item: ListItemProps = {
      title: replaceTags(t(`${pg.label}` || ""), tags),
      text: replaceTags(t(pg.text), tags),
    };
    return item;
  });

  return <List type="none" items={items} />;
};

export default BulletList;
