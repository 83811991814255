import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ProgramChangeWishContent from "./program-change-wish";

export const programChangeWishContentValidator: ValidatorMethod = (
  state,
  _t,
) => {
  const { programChangeWish = [] } = state;
  const result = programChangeWish.length >= 1 ? "pass" : "fail";
  return result;
};

export const programChangeWishSchema: ContentSchema = {
  Component: ProgramChangeWishContent,
  validator: programChangeWishContentValidator,
};
