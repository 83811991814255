import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ListContent from "./list";

export const listContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const listSchema: ContentSchema = {
  Component: ListContent,
  validator: listContentValidator,
};
