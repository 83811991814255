import { Fragment, FunctionComponent, useEffect, useRef } from "react";
import { ContentProps } from "../factory";
import { ChatbotContentType } from "models/content/chatbot";
import HelmetWrapper from "components/helmet";
import { sendChatbotOpenEvent } from "services/event";
import { useConfig } from "hooks/config";

interface ChatbotContentProps extends ContentProps {
  content: ChatbotContentType;
}

const ChatbotContent: FunctionComponent<ChatbotContentProps> = () => {
  const config = useConfig();
  const widget = useRef<any | undefined>(undefined);

  useEffect(() => {
    const interval = setInterval(() => {
      const { zE } = window as any;
      if (zE !== undefined) {
        clearInterval(interval);
        widget.current = zE;

        widget.current("messenger:on", "open", function () {
          sendChatbotOpenEvent(config.uuid);
        });
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <HelmetWrapper>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=7c367441-3993-4cc9-bb21-e65df280f3c4"
        />
      </HelmetWrapper>
    </Fragment>
  );
};

export default ChatbotContent;
