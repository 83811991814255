import { TagMap } from "lib/tag";
import { OptionModel } from "models/option";
import { FunctionComponent } from "react";
import { ContentType } from "models/content";
import { ValidatorMethod } from "../validations";

// schemas
import { titleSchema } from "./types/title.utils";
import { descriptionSchema } from "./types/description.utils";
import { footnoteSchema } from "./types/footnote.utils";
import { signupEmailSchema } from "./types/signup-email.utils";
import { signupPasswordSchema } from "./types/signup-password.utils";
import { signupNameSchema } from "./types/signup-name.utils";
import { programPromiseSchema } from "./types/program-promise.utils";
import { studyScheduleSchema } from "./types/study-schedule.utils";
import { daySelectionSchema } from "./types/day-selection.utils";
import { daySelectionConfirmSchema } from "./types/day-selection-confirm.utils";
import { proofProgressSchema } from "./types/proof-progress.utils";
import { listSchema } from "./types/list.utils";
import { slideshowSchema } from "./types/slideshow.utils";
import { preparingProgramSchema } from "./types/preparing-program.utils";
import { preparingProgramSimpleSchema } from "./types/preparing-program-simple.utils";
import { paragraphsSchema } from "./types/paragraphs.utils";
import { guaranteeSchema } from "./types/guarantee.utils";
import { paymentSchema } from "./types/payment.utils";
import { appRatingSchema } from "./types/app-rating.utils";
import { waitSchema } from "./types/wait.utils";
import { appStoreButtonSchema } from "./types/app-store-button.utils";
import { startHereImageSchema } from "./types/start-here-image.utils";
import { tableSchema } from "./types/table.utils";
import { testimonialSchema } from "./types/testimonial.utils";
import { imageSchema } from "./types/image.utils";
import { bubbleSchema } from "./types/bubble.utils";
import { priceSchema } from "./types/price.utils";
import { skillsToImproveSchema } from "./types/skills-to-improve.utils";
import { moreInfoOfSchema } from "./types/more-info-of.utils";
import { programChangeWishSchema } from "./types/program-change-wish.utils";
import { paddleSchema } from "./types/paddle.utils";
import { scheduleSummarySchema } from "./types/schedule-summary.utils";
import { chatbotSchema } from "./types/chatbot.utils";

export const buildSchema = (content: ContentType): ContentSchema => {
  const { type } = content;
  switch (type) {
    case "title":
      return titleSchema;
    case "description":
      return descriptionSchema;
    case "footnote":
      return footnoteSchema;
    case "signupEmail":
      return signupEmailSchema;
    case "signupPassword":
      return signupPasswordSchema;
    case "signupName":
      return signupNameSchema;
    case "programPromise":
      return programPromiseSchema;
    case "studySchedule":
      return studyScheduleSchema;
    case "daySelection":
      return daySelectionSchema;
    case "daySelectionConfirm":
      return daySelectionConfirmSchema;
    case "proofProgress":
      return proofProgressSchema;
    case "list":
      return listSchema;
    case "slideshow":
      return slideshowSchema;
    case "wait":
      return waitSchema;
    case "preparingProgram":
      return preparingProgramSchema;
    case "preparingProgramSimple":
      return preparingProgramSimpleSchema;
    case "paragraphs":
      return paragraphsSchema;
    case "guarantee":
      return guaranteeSchema;
    case "payment":
      return paymentSchema;
    case "appRating":
      return appRatingSchema;
    case "appStoreButton":
      return appStoreButtonSchema;
    case "startHereImage":
      return startHereImageSchema;
    case "table":
      return tableSchema;
    case "testimonial":
      return testimonialSchema;
    case "image":
      return imageSchema;
    case "bubble":
      return bubbleSchema;
    case "price":
      return priceSchema;
    case "skillsToImprove":
      return skillsToImproveSchema;
    case "moreInfoOf":
      return moreInfoOfSchema;
    case "programChangeWish":
      return programChangeWishSchema;
    case "paddle":
      return paddleSchema;
    case "scheduleSummary":
      return scheduleSummarySchema;
    case "chatbot":
      return chatbotSchema;
    default:
      throw new Error(`Unsupported questionnaire content type: ${type}`);
  }
};

export interface OptionClickInterface {
  onOptionClick: (option: OptionModel) => void;
}

export interface ContentProps {
  content: any;
  tags: TagMap;
  initial: boolean;
  onChange: () => void; // signal content status might have changes ==> runs validations
  onSubmit: () => void; // allows contents to trigger navigation

  // allows navigation
  handleNavigation: (option: OptionModel, scroll: boolean) => Promise<void>;

  // gives a reference to scrollInstance
  handleScroll: () => void;
}

export interface ContentSchema {
  Component: FunctionComponent<ContentProps>;
  validator: ValidatorMethod;
}
