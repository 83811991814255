import { useTheme } from "hooks/theme";
import { ThemeProps } from "models/theme";
import { FunctionComponent } from "react";
import { createGlobalStyle, css } from "styled-components";
import { ColorCode } from "./colors";
import { breakpoints } from "./devices";
import { archivo, light, opensans, regular, semibold } from "./fonts";

// cookie consent styles
import { onetrust } from "./onetrust";

export const GlobalStyleSheet: FunctionComponent = () => {
  const theme = useTheme();
  return <GlobalStyles $theme={theme} />;
};

const GlobalStyles = createGlobalStyle<ThemeProps>`
  *, ::after, ::before {
    box-sizing: border-box;
  }

  ${(props) => onetrust(props.$theme || "MasterEnglish")};
  
  html, body {
    padding: 0;
    margin: 0;
    overscroll-behavior-y: none;

    ${opensans()};
    ${regular()};
    font-size: 16px;
    line-height: 1.5;
    color: ${ColorCode.GRAY800};

    ${(props) =>
      props.$theme === "MasterEnglish" &&
      css`
        ${light()};
        line-height: 1.75;
        color: ${ColorCode.BLACK};
      `}

    ${(props) =>
      props.$theme === "HablaIngles" &&
      css`
        ${archivo()};
        background-color: ${ColorCode.HEX_F4F4F4};
        color: ${ColorCode.HEX_7F7F7F};
      `}

  }

  .noscroll {
    overflow: hidden;
  }

  h1 {
    ${semibold()};
    font-size: 2em;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
    color: ${ColorCode.BLACK};

    ${(props) =>
      props.$theme === "HablaIngles" &&
      css`
        ${archivo()};
        color: #273c7c;
      `}

    a {
      ${semibold()};
      ${(props) =>
        props.$theme === "HablaIngles" &&
        css`
          ${archivo()};
          color: #273c7c;
        `}
    }

  }

  h2 {
    ${semibold()};
    font-size: 1.2em;
    color: ${ColorCode.BLACK};
    line-height: 1.5rem;
    margin-top: 2rem;
    ${(props) =>
      props.$theme === "HablaIngles" &&
      css`
        ${archivo()};
      `}
  }

  .form-control {
    margin-bottom: 1rem;
  }

  b, strong {
    ${semibold()};
  }

  pre {
    ${semibold()};
    font-size: 1em;
    color: ${ColorCode.GRAY800};

    white-space: break-spaces;
    word-break: break-word;
  }

  li {
    color: ${ColorCode.GRAY800};
    margin: 0 0 1rem 0;

    a {
      vertical-align: top;
    }
    &::marker {
      vertical-align: top;
    }
  }

  dt {
    ${semibold()};
  }

  dd {
    list-style-type: none;
    margin: 0 0 1rem 0;
    padding: 0;
  }

  a, .link {
    white-space: break-spaces;
    word-break: break-word;

    color: ${ColorCode.BLUE};
    display: inline-block;

    &:hover {
      color: ${ColorCode.LIGHT_BLUE};
    }
  }

  *[responsive] {
    display: none;

    &[mobile] {
      @media (max-width: ${breakpoints.tablet - 1}px) {
        display: unset;
      }
    }

    &[tablet] {
      @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px) {
        display: unset;
      }
    }

    &[desktop] {
      @media (min-width: ${breakpoints.desktop}px) {
        display: unset;
      }
    }
  }

  .message-animation-enter {
    opacity: 0;
    transform: translateY(1rem);
  }

  .message-animation-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }

  .message-animation-exit-active, .message-animation-exit-done {
    opacity: 0;
    transform: translateY(1rem);
  }
`;
