import HelmetWrapper from "components/helmet";
import { useRootUrl, useRoute, useRouteParams } from "hooks/route";
import { LocalePathMap, Locales, LocaleType } from "models/locale";

const AlternateUrls = () => {
  const route = useRoute();
  const params = useRouteParams();
  const fullUrl = useRootUrl();

  if (!route) {
    return null;
  }

  const alternatives: any[] = [];

  const { pathSets } = route;
  for (const uncastedLocale in pathSets) {
    const locale = uncastedLocale as LocaleType;

    // ignore not supported languages
    if (Locales.includes(locale) === false) {
      continue;
    }

    // Add alternative
    const set = pathSets[locale];
    if (set) {
      let { path } = set;
      for (const key in params) {
        path = path.replace(`:${key}`, params[key] || "");
      }

      alternatives.push({
        locale,
        path: `${fullUrl}/${LocalePathMap[locale]}/${path}`,
      });
    }
  }

  return (
    <HelmetWrapper>
      {alternatives.map((alternative) => (
        <link
          key={alternative.locale}
          rel="alternate"
          hrefLang={alternative.locale}
          href={alternative.path}
        />
      ))}
    </HelmetWrapper>
  );
};

export default AlternateUrls;
