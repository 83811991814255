import { Currency } from "lib/currency";
import { PaymentProvider } from "./payment";
import { ProgramPlan } from "./plan";

export const PRODUCT_KEY = "product";

export interface ProductModel {
  currency: Currency;
  paymentProvider: PaymentProvider;
  price: number;
  listPrice?: number;
  productId: string;
  productPlan?: ProgramPlan;
  productTitle: string;
  productType: ProductType;
  trialDays?: number;
  frequency: number;
}

export type ProductType = "subscription" | "onetime";

export interface ProductConfiguration {
  productType?: ProductType;
  productPlan?: ProgramPlan;
  currency?: Currency;

  // texts for the button
  selectionTexts: {
    trial?: string;
    header: string;
    price: string;
    footer?: string;
  };

  // some text overrides
  paymentTexts?: {
    paymentHeader?: string;
    typeText?: string;
    paymentFooter?: string;
  };

  // possible discount indicator
  discounts?: DiscountModel[];
}

interface DiscountModel {
  currency: Currency;
  percentage: number;
  tag: string;
}

export const DEFAULT_PRODUCT_CONFIGURATIONS: ProductConfiguration[] = [
  {
    productType: "onetime",
    selectionTexts: {
      trial: "Prueba gratuita de 7 días",
      header: "1 Pago único",
      price: "#ME_TOTAL_FEE#",
    },
  },
  {
    productType: "subscription",
    selectionTexts: {
      trial: "Prueba gratuita de 7 días",
      header: "#ME_MONTHS# Meses sin intereses",
      price: "#ME_MONTHS#X #ME_FEE#",
      footer: "#ME_TOTAL_FEE# en total",
    },
  },
];

export const NO_PRODUCT_CONFIGURATION: ProductConfiguration = {
  paymentTexts: {
    paymentFooter: "NO PRODUCT CONFIGURATION",
    typeText: "NO PRODUCT CONFIGURATION",
  },
  productPlan: "3M",
  productType: "subscription",
  selectionTexts: {
    footer: "NO PRODUCT CONFIGURATION",
    header: "NO PRODUCT CONFIGURATION",
    price: "NO PRODUCT CONFIGURATION",
  },
};
