import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ProgramPromiseContent from "./program-promise";

export const programPromiseContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const programPromiseSchema: ContentSchema = {
  Component: ProgramPromiseContent,
  validator: programPromiseContentValidator,
};
