import { request } from "lib/request";
import { ConfigModel } from "models/config";
import { CountryType, LocaleType } from "models/locale";
import { ProductModel } from "models/product";
import { fetchProductIds } from "./feature";
import { ProgramPlan } from "models/plan";

export interface ProductsResponse {
  customerCountry: CountryType;
  products: ProductModel[];
}

interface Props {
  config: ConfigModel;
  questionnaire: string; // in 'v23' format
  plan: ProgramPlan;
  minutesPerWeek?: string;
  reactivate: boolean; // defaults to false
  locale: LocaleType;
  couponCode?: string;
}

export const fetchProducts = async (props: Props) => {
  const { config, couponCode, questionnaire, plan, reactivate, locale } = props;

  // fetch fetch product ids (this is a string with multiple ids concatted)
  const productIds = await fetchProductIds(config.uuid, {
    questionnaire,
    plan,
    reactivate,
    locale,
  });

  // then fetch products
  const params = {
    productIds,
    customerCountry: config.country,
    couponCode,
  };

  // fetch products
  const response = await request<ProductsResponse>({
    method: "GET",
    url: "/v1/products",
    params,

    // not not auth this endpoint
    authenticated: false,
  });

  // sort a bit and return
  const products = response.data.products || [];
  return sortProducts(products);
};

const sortProducts = (products: ProductModel[]) => {
  return products.sort((a, b) => {
    const valueA = getSortValue(a);
    const valueB = getSortValue(b);
    return valueA - valueB;
  });
};

const getSortValue = (product: ProductModel) => {
  const { productType, productPlan = "1M" } = product;

  // first catch by product type
  if (productType === "onetime") {
    return 0;
  }

  // secondarily, if a weekly sub then...
  if (productPlan.includes("W")) {
    return 1;
  }

  return 2;
};
