import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import Wait from "components/wait";
import { WaitContentType } from "models/content/wait";

interface WaitContentProps extends ContentProps {
  content: WaitContentType;
}

const WaitContent: FunctionComponent<WaitContentProps> = ({ content }) => {
  return <Wait text={content.waitText} />;
};

export default WaitContent;
