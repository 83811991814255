import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import FootnoteContent from "./footnote";

export const footnoteContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const footnoteSchema: ContentSchema = {
  Component: FootnoteContent,
  validator: footnoteContentValidator,
};
