import Input from "components/form/input";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { useTranslations } from "hooks/translations";
import { isLocal } from "lib/env";
import { createLinkTag, replaceTags } from "lib/string";
import {
  passwordValidator,
  requiredValidator,
  ValidatorFn,
} from "lib/validators";
import { SignupPasswordContentType } from "models/content/signup-password";
import { Fragment, FunctionComponent, useState } from "react";
import { ContentProps } from "../factory";
import { useLink } from "hooks/route";
import { generateRandomPassword } from "./signup-password.utils";

interface SignupPasswordContentProps extends ContentProps {
  content: SignupPasswordContentType;
  onSubmit: () => void;
}

const SignupPasswordContent: FunctionComponent<SignupPasswordContentProps> = ({
  onChange,
  onSubmit,
}) => {
  const t = useTranslations();
  const [password, setPassword] = useState("");
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const ppLink = useLink("privacy");
  const tocLink = useLink("terms-and-conditions");

  const randomise = () => {
    // create a randon email and change state, only works in local setup
    const password = generateRandomPassword();
    handlePasswordChange(password);
  };

  const handlePasswordChange = (password: string) => {
    // internal state
    setPassword(password);

    // store new value to the public state
    state.user.password = password;
    updateQuestionnaireState(state);

    // signal question level
    onChange();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const validators: ValidatorFn[] = [
    requiredValidator(t),
    passwordValidator(t),
  ];

  const renderHint = () => {
    const __html = replaceTags(t("hint_agree_on_terms_and_privacy"), {
      TOC_LINK: createLinkTag(tocLink, t("terms_and_conditions"), true),
      PRIVACY_LINK: createLinkTag(ppLink, t("footer_privacy_policy"), true),
    });
    return <span dangerouslySetInnerHTML={{ __html }}></span>;
  };

  return (
    <Fragment>
      {isLocal() && (
        <div onClick={randomise}>[Click here to generate password]</div>
      )}
      <Input
        hidden
        unique
        type="text"
        value={state.user.email}
        autoComplete="username"
        placeholder=""
        onChange={() => {
          /* do nothing */
        }}
      />
      <Input
        unique
        type="password"
        autoFocus
        inputMode="text"
        enterKeyHint="go"
        hint={renderHint()}
        value={password}
        placeholder={t("label_password")}
        onChange={handlePasswordChange}
        onSubmit={handleSubmit}
        validators={validators}
        autoComplete="new-password"
      />
    </Fragment>
  );
};

export default SignupPasswordContent;
