import { ViewProps } from "models/view";
import styled, { css, keyframes } from "styled-components";
import { ColorCode } from "theme/colors";

export const frameStyle =
  "width:100%; min-width:200px; background-color: transparent; border: none;";

interface FrameProps extends ViewProps {
  $isLoading: boolean;
}

export const StyledFrame = styled.div<FrameProps>`
  min-height: 200px;
  background-color: ${ColorCode.WHITE};
  border-radius: 12px;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${(props) =>
    props.$view === "light" &&
    css`
      box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    `}

  ${(props) =>
    props.$isLoading &&
    css`
      iframe {
        display: none;
      }
    `}
`;

export const StyledSpinner = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledText = styled.div`
  display: flex;
  flex: 0;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  border-top: 1px solid ${ColorCode.GRAY500};
  color: ${ColorCode.GRAY500};
`;

const load8 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.25em solid rgba(0, 0, 0, 0.2);
  border-right: 0.25em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.25em solid rgba(0, 0, 0, 0.2);
  border-left: 0.25em solid ${ColorCode.WHITE};
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;
`;
