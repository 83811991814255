import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import WaitContent from "./wait";

export const waitContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const waitSchema: ContentSchema = {
  Component: WaitContent,
  validator: waitContentValidator,
};
