import { createRoot } from "react-dom/client";
import App from "app";

import dayjs from "dayjs";

// dayjs locales
import("dayjs/locale/es");
import("dayjs/locale/es-mx");
import("dayjs/locale/pt-br");
import("dayjs/locale/it");
import("dayjs/locale/de");

// extend dayjs
import localeData from "dayjs/plugin/localeData";
dayjs.extend(localeData);

// react root
createRoot(document.getElementById("root")!).render(<App />);
