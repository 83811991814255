import { LocalePathMap, Locales, LocaleType } from "models/locale";
import { usePageLocale, useRoute, useRouteParams } from "hooks/route";
import { useNavigate } from "react-router";
import {
  StyledImage,
  StyledLabel,
  StyledLocaleMobile,
  StyledSelect,
} from "./locale-mobile.styles";
import { MediaSchema } from "lib/media";
import { useTranslations } from "hooks/translations";

export const LocaleMobile = () => {
  const route = useRoute();
  const params = useRouteParams();
  const navigate = useNavigate();
  const t = useTranslations();
  const locale = usePageLocale();

  const changeTo = (locale: LocaleType) => {
    if (route) {
      const { pathSets = {} } = route;
      const set = pathSets[locale];
      if (set) {
        let { path } = set;
        for (const key in params) {
          path = path.replace(`:${key}`, params[key] || "");
        }

        navigate(`/${LocalePathMap[locale]}/${path}`);
      }
    }
  };

  const handleChange = (event: any) => {
    const locale = event.target.value as LocaleType;
    changeTo(locale);
  };

  const schema: MediaSchema = {
    filename: "locale",
    tablet: false,
    desktop: false,
    extension: "svg",
  };

  return (
    <StyledLocaleMobile>
      <StyledLabel htmlFor="language">{t("footer_menu_locale")}:</StyledLabel>
      <StyledSelect onChange={handleChange} value={locale} id="language">
        {Locales.map((loc) => {
          return (
            <option key={loc} value={loc}>
              {t(loc)}
            </option>
          );
        })}
      </StyledSelect>
      <StyledImage schema={schema} alt="Change language" />
    </StyledLocaleMobile>
  );
};

export default LocaleMobile;
