import { FunctionComponent, useEffect, useState } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import Input from "components/form/input";
import { read, write } from "lib/storage";
import {
  emailValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import Tiles from "components/tiles";
import { updateEmail } from "services/update-email";
import { SIGNUP_EMAIL_KEY } from "models/user";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import Navbar from "components/navigation/navbar";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router";
import { StaticViewIdMap } from "models/question";
import { useStaticLink } from "hooks/route";

export const USED_EMAIL_KEY = "usedEmail";

const QuestionnaireUpdateEmailView: FunctionComponent = () => {
  const navigate = useNavigate();
  const questionnaire = useQuestionnaire();
  const t = useTranslations();
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const config = useConfig();

  const [disabled, toggleDisabled] = useState(false);
  const [email, toggleEmail] = useState("");
  const [loading, toggleLoading] = useState(false);

  const version = getVersion(questionnaire);
  const verifyEmailLink = useStaticLink(version, "verify-email");
  const emailExistsLink = useStaticLink(version, "email-exists");

  useEffect(() => {
    // set initial value in the beginning
    const value = read<string>(SIGNUP_EMAIL_KEY);
    if (value) {
      toggleEmail(value);
    }

    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdMap["update-email"],
    });
  }, []);

  const handleEmailChange = (newEmail: string) => {
    toggleEmail(newEmail);
    if (runValidations(newEmail, validators) !== undefined) {
      toggleDisabled(true);
    } else {
      toggleDisabled(false);
    }
  };

  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];

  const handleClick = async () => {
    toggleLoading(true);

    try {
      // change email to server
      await updateEmail(email);

      // store new email to storage and state
      write(SIGNUP_EMAIL_KEY, email);
      state.user.email = email;
      updateQuestionnaireState(state);
      navigate(verifyEmailLink);
    } catch (_err) {
      // fails, must be this case
      write<string>(USED_EMAIL_KEY, email);
      navigate(emailExistsLink);
    }

    toggleLoading(false);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("update_email_button1"),
        type: "primary",
        disabled: disabled,
        onClick: handleClick,
        $loadingStatus: loading,
        href: verifyEmailLink,
      },
    },
  ];

  return (
    <View
      title={t("update_email_title")}
      description={t("update_email_p1")}
      cover
    >
      <Navbar localeDropdown={false} />
      <Tiles />
      <ContentContainer>
        <Title>{t("update_email_title")}</Title>
        <Description>{t("update_email_p1")}</Description>
        <Input
          unique
          type="email"
          inputMode="email"
          autoFocus
          value={email}
          validators={validators}
          placeholder={email}
          onChange={handleEmailChange}
          autoComplete="username"
        />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireUpdateEmailView;
