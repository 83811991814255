import { request } from "lib/request";
import { LocaleType } from "models/locale";
import { ProgramPlan } from "models/plan";

export interface FeatureResponse {
  flag: string;
  value: string;
}

// generic feature request service
async function featureRequest(feature: string, data: any) {
  const response = await request<FeatureResponse>({
    method: "POST",
    url: `/v1/feature/${feature}`,
    data,
  });

  // convert value
  const { value } = response.data;
  return value;
}

export interface ProductIdsRequestParams {
  questionnaire: string; // in 'v23' format
  plan: ProgramPlan;
  minutesPerWeek?: string;
  reactivate: boolean; // defaults to false
  locale: LocaleType;
}

export const fetchProductIds = async (
  trafficId: string,
  attributes: ProductIdsRequestParams,
) => {
  const productIds = await featureRequest("course-fee", {
    trafficId,
    attributes,
  });
  return productIds;
};
