import { LocaleType } from "models/locale";

export type Currency = "USD" | "MXN" | "ARS" | "EUR" | "BRL";

/*

AS AN ULTIMATE BACKUP USE THIS LOCALE MAPPING INSTEAD
const getCurrencyLocale = (currency: Currency) => {
  switch (currency) {
    case "MXN":
      return "es-MX";
    case "ARS":
      return "es-AR";
    case "EUR":
      return "de-DE";
    case "BRL":
      return "pt-BR";
    default:
      return "en-US";
  }
};

*/
// creates a currency formatter
export const format = (
  amount: number,
  currency: Currency,
  locale: LocaleType,
) => {
  // for backwards compability
  const formattingLocale = locale === "es-419" ? "es-MX" : `${locale}`;

  // format string
  const formatter = new Intl.NumberFormat(formattingLocale, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });

  const parts = formatter
    // format to array
    .formatToParts(amount)

    // remove currency
    .filter((p) => p.type !== "currency")

    // map to only values
    .map((p) => p.value);

  // join all
  const formatted = parts.join("");
  return formatted.trim();
};

// formats a single currency
export const formatCurrency = (
  amount: number,
  currency: Currency,
  locale: LocaleType,
) => {
  const formatted = format(amount, currency, locale);
  const prefix = getCurrencyPrefix(currency);
  const suffix = getCurrencySuffix(currency);
  const parts = [prefix, formatted, " ", suffix];
  return parts.join("");
};

export const formatCurrencyRange = (
  from: number,
  to: number,
  currency: Currency,
  locale: LocaleType,
) => {
  const formattedFrom = format(from, currency, locale);
  const formattedTo = format(to, currency, locale);
  const prefix = getCurrencyPrefix(currency);

  return [
    `${prefix}${formattedFrom.replace(currency, "")}`,
    `${prefix}${formattedTo}`,
  ].join(" - ");
};

export const getCurrencyPrefix = (currency: Currency) => {
  switch (currency) {
    case "BRL":
      return "R$";
    case "EUR":
      return "€";
    case "USD":
    case "MXN":
    case "ARS":
    default:
      return "$";
  }
};

export const getCurrencySuffix = (currency: Currency) => {
  return currency;
};
