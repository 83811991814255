import { MediaSchema } from "lib/media";
import { WallpaperModel } from "models/question";
import styled, { css } from "styled-components";
import { alpha, ColorCode } from "theme/colors";

interface WallpaperProps {
  lowered: boolean;
}

export const StyledWallpaper = styled.div<WallpaperProps>`
  position: absolute;
  left: 0;
  top: ${(props) => (props.lowered ? "59px" : 0)};
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

export const WallpaperCSS = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

export const StyledGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  background: ${ColorCode.LAYOUT_DARK};
  background: linear-gradient(
    180deg,
    ${ColorCode.LAYOUT_DARK} 0%,
    ${alpha(ColorCode.LAYOUT_DARK, 0.75)} 50%,
    ${ColorCode.HEX_REDDISH} 100%
  );
`;

export const parseWallpaperMediaSchema = (wallpaper: WallpaperModel) => {
  const { image } = wallpaper;

  const schema: MediaSchema = {
    filename: image,
    extension: "png",
    desktop: true,
  };
  return schema;
};
