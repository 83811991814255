import { MediaSchema } from "lib/media";
import styled, { css } from "styled-components";

export const parseBadgeMediaSchema = (filename: string) => {
  const schema: MediaSchema = {
    filename: `badge/${filename}`,
    extension: "svg",
    localised: true,
  };
  return schema;
};

export const StyledWrapper = styled.div`
  position: relative;
  z-index: 75;
`;

export const ImageCSSHorizontal = css`
  position: absolute;
  z-index: 50;
  width: 108px;
  height: 108px;
  margin: 0;
  right: 1.25rem;
  top: 1rem;

  animation: entry 1s cubic-bezier(0.83, 0, 0.17, 1) forwards;

  @keyframes entry {
    0% {
      opacity: 0;
      transform: translate(300px, 0);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;

export const ImageCSSVertical = css`
  position: absolute;
  width: 108px;
  height: 108px;
  margin: 0;
  right: 1.25rem;
  top: 1rem;

  animation: entry 1s cubic-bezier(0.83, 0, 0.17, 1) forwards;

  @keyframes entry {
    0% {
      opacity: 0;
      transform: translate(0, 75px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;
