import Description from "components/description";
import { replaceTags, toHTML } from "lib/string";
import { DescriptionContentType } from "models/content/description";
import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import { useTranslations } from "hooks/translations";

interface DescriptionContentProps extends ContentProps {
  content: DescriptionContentType;
}

const DescriptionContent: FunctionComponent<DescriptionContentProps> = ({
  content,
  tags,
}) => {
  const translate = useTranslations();
  const description = toHTML(replaceTags(translate(content.text), tags));
  return <Description>{description}</Description>;
};

export default DescriptionContent;
