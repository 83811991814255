import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { QuestionModel } from "models/question";
import { FunctionComponent, useEffect, useState } from "react";
import { StyledPreload } from "./preload.styles";
import { getFollowUpIds } from "lib/question";
import { parseHeaderMediaSchema } from "./header.styles";
import { parseWallpaperMediaSchema } from "./wallpaper.styles";
import { getQuestionById } from "lib/questionnaire";
import { QuestionnaireModel } from "models/questionnaire";
import { SlideshowContentType } from "models/content/slideshow";
import { ImageContentType } from "models/content/image";
import { BubbleContentType } from "models/content/bubble";
import { parseSlideshowMediaSchema } from "views/questionnaire/question/content/base/types/slideshow.utils";
import { parseImageSchema } from "views/questionnaire/question/content/base/types/image.styles";
import { parseBubbleImageSchema } from "views/questionnaire/question/content/base/types/bubble.styles";
import { MediaSchema } from "lib/media";
import { parseMediaUrl } from "hooks/media";
import { LocaleType } from "models/locale";
import { usePageLocale } from "hooks/route";

interface PreloadProps {
  question: QuestionModel;
}

const Preload: FunctionComponent<PreloadProps> = ({ question }) => {
  const questionnaire = useQuestionnaire();
  const locale = usePageLocale();
  const state = useQuestionnaireState();

  const [mediaUrls, toggleMediaUrls] = useState<string[]>([]);

  useEffect(() => {
    const preload = () => {
      const currentQuestion =
        question ||
        getQuestionById(questionnaire, questionnaire.initialQuestion);

      if (!currentQuestion) {
        // abort if no question found
        return;
      }

      // get next ids (remove visited ones)
      const nextIds = getFollowUpIds(currentQuestion).filter(
        (id) => !state.visitedQuestionIds.includes(id),
      );

      // parse next images
      const mediaUrls = getMediaUrls(nextIds, questionnaire, locale);
      toggleMediaUrls(mediaUrls);
    };

    // start preload
    preload();
  }, [question.id]);

  return (
    <StyledPreload>
      {mediaUrls.map((url) => {
        return <img key={url} src={url} alt={url} />;
      })}
    </StyledPreload>
  );
};

export default Preload;

const getMediaUrls = (
  nextIds: number[],
  questionnaire: QuestionnaireModel,
  locale: LocaleType,
) => {
  const mediaUrls: string[] = [];
  nextIds.forEach((id) => {
    const question = getQuestionById(questionnaire, id);
    if (question) {
      // parse each kind of image
      getQuestionWallpaperUrls(mediaUrls, question, locale);
      getHeaderUrls(mediaUrls, question, locale);
      getQuestionSlideshowContentUrls(mediaUrls, question, locale);
      getImageContentUrls(mediaUrls, question, locale);
      getBubbleContentUrls(mediaUrls, question, locale);
    }
  });

  // filter out undefined ones and create a plain array
  let uniqueUrls: string[] = [...mediaUrls.filter((url) => url !== undefined)];

  // remove duplicates
  uniqueUrls = uniqueUrls.filter(
    (url, index) => uniqueUrls.indexOf(url) === index,
  );

  return uniqueUrls;
};

const getQuestionWallpaperUrls = (
  mediaUrls: string[],
  question: QuestionModel,
  locale: LocaleType,
) => {
  if (question.wallpaper) {
    const schema = parseWallpaperMediaSchema(question.wallpaper);
    push(mediaUrls, schema, locale);
  }
};

const getHeaderUrls = (
  mediaUrls: string[],
  question: QuestionModel,
  locale: LocaleType,
) => {
  if (question.header) {
    const schema = parseHeaderMediaSchema(question.header);
    push(mediaUrls, schema, locale);
  }
};

const getQuestionSlideshowContentUrls = (
  mediaUrls: string[],
  question: QuestionModel,
  locale: LocaleType,
) => {
  const slideshowContents = (question.contents || []).filter(
    (c) => c.type === "slideshow",
  );
  slideshowContents.forEach((c) => {
    const { slideshowOptions } = c as SlideshowContentType;
    if (slideshowOptions && slideshowOptions.items) {
      slideshowOptions.items.forEach((item) => {
        const schema = parseSlideshowMediaSchema(item);
        push(mediaUrls, schema, locale);
      });
    }
  });
};

const getImageContentUrls = (
  mediaUrls: string[],
  question: QuestionModel,
  locale: LocaleType,
) => {
  const imageContents = (question.contents || []).filter(
    (c) => c.type === "image",
  );
  imageContents.forEach((c) => {
    const { imageOptions } = c as ImageContentType;
    const schema = parseImageSchema(imageOptions);
    push(mediaUrls, schema, locale);
  });
};

const getBubbleContentUrls = (
  mediaUrls: string[],
  question: QuestionModel,
  locale: LocaleType,
) => {
  const bubbleContents = (question.contents || []).filter(
    (c) => c.type === "bubble",
  );
  bubbleContents.forEach((c) => {
    const { bubbleOptions } = c as BubbleContentType;
    const schema = parseBubbleImageSchema(bubbleOptions.filename);
    push(mediaUrls, schema, locale);
  });
};

// adds items from ItemUrls collection to imageUrls string array
const push = (mediaUrls: string[], schema: MediaSchema, loc: LocaleType) => {
  const {
    filename: fn,
    extension,
    tablet = false,
    desktop = false,
    localised: l = false,
  } = schema;
  // add basic mobile url
  const mSrc = parseMediaUrl(fn, "mobile", extension, l ? loc : undefined);
  mediaUrls.push(mSrc);

  // add mobile webp
  if (extension === "png") {
    const mWebpSrc = parseMediaUrl(fn, "mobile", "webp", l ? loc : undefined);
    mediaUrls.push(mWebpSrc);
  }

  // add tablet
  if (tablet) {
    const tabletSrc = parseMediaUrl(
      fn,
      "tablet",
      extension,
      l ? loc : undefined,
    );
    mediaUrls.push(tabletSrc);

    // add tablet webp
    if (extension === "png") {
      const tabletWebpSrc = parseMediaUrl(
        fn,
        "tablet",
        "webp",
        l ? loc : undefined,
      );
      mediaUrls.push(tabletWebpSrc);
    }
  }

  // add desktop
  if (desktop) {
    const desktopSrc = parseMediaUrl(
      fn,
      "tablet",
      extension,
      l ? loc : undefined,
    );
    mediaUrls.push(desktopSrc);

    // add tablet webp
    if (extension === "png") {
      const desktopWebpSrc = parseMediaUrl(
        fn,
        "desktop",
        "webp",
        l ? loc : undefined,
      );
      mediaUrls.push(desktopWebpSrc);
    }
  }
};
