import dayjs from "dayjs";
import { ConfigModel } from "models/config";
import { ProductModel } from "models/product";
import { StateModel } from "models/state";
import { SIGNUP_EMAIL_KEY, SIGNUP_NAME_KEY } from "models/user";
import { formatCurrency } from "./currency";
import { formatLong, formatShort, getMonthName } from "./date";
import {
  getProgramTotal,
  getSubscriptionFee,
  getSubscriptionTotal,
} from "./price";
import {
  getStudySchedule,
  getInitialLevelSelection,
  getTargetLevelSelection,
} from "./question";
import { read } from "./storage";
import { getProductConfiguration } from "./product-configuration";
import { QuestionnaireModel } from "models/questionnaire";
import { StudyScheduleModel } from "models/content/study-schedule";
import { LocaleType, TranslateFn } from "models/locale";

export interface TagMap {
  [key: string]: string | number;
}

export const getStudyScheduleTags = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  translate: TranslateFn,
) => {
  const studySchedule = getStudySchedule(questionnaire, state);
  const tags: TagMap = {};
  if (studySchedule) {
    const {
      months,
      weeks = 13,
      breakWeeks = 3,
      practiseDaysPerWeek, // default this to 5 later
      minutesOnApp = 0,
      minutesOnLessons = 0,
      minutesPerSession = 0,
      minutesOnRecordedLessons = 0,
      minutesOnInteractiveExercises = 0,
    } = studySchedule;
    const startDate = dayjs();
    const endDate = startDate.add(months, "month").subtract(1, "day");

    // calculate weekly summaries
    const weeklyTotalMinutes = practiseDaysPerWeek * minutesPerSession;
    const weeklyHours = Math.floor(weeklyTotalMinutes / 60);
    const weeklyMinutes = weeklyTotalMinutes % 60;
    let fullWeeklyTime = translate(
      weeklyMinutes === 0
        ? "#HOURS# hours"
        : "#HOURS# hours and #MINUTES# minutes",
    );
    let shortWeeklyTime = translate(
      weeklyMinutes === 0 ? "#HOURS# h" : "#HOURS#h and #MINUTES#min",
    );

    fullWeeklyTime = fullWeeklyTime.replace("#HOURS#", weeklyHours.toString());
    shortWeeklyTime = shortWeeklyTime.replace(
      "#HOURS#",
      weeklyHours.toString(),
    );

    if (weeklyMinutes > 0) {
      fullWeeklyTime = fullWeeklyTime.replace(
        "#MINUTES#",
        weeklyMinutes.toString(),
      );
      shortWeeklyTime = shortWeeklyTime.replace(
        "#MINUTES#",
        weeklyMinutes.toString(),
      );
    }

    // tag allocation
    tags.MONTHS = months;
    tags.WEEKS = weeks;
    tags.MINUTES = minutesPerSession;
    tags.DAYS = practiseDaysPerWeek || 5;
    tags.START_DATE = formatShort(startDate);
    tags.END_DATE = formatShort(endDate);
    tags.START_DATE_LONG = formatLong(startDate);
    tags.END_DATE_LONG = formatLong(endDate);
    tags.BREAK_WEEKS = breakWeeks;
    tags.TOTAL_WEEKLY_TIME = fullWeeklyTime;
    tags.TOTAL_WEEKLY_TIME_SHORT = shortWeeklyTime;
    tags.MINUTES_PER_SESSION = minutesPerSession;
    tags.PRACTICE_DAYS_PER_WEEK = practiseDaysPerWeek || 5;
    tags.MINUTES_ON_APP = minutesOnApp;
    tags.MINUTES_ON_LESSONS = minutesOnLessons;
    tags.MINUTES_ON_RECORDED_LESSONS = minutesOnRecordedLessons;
    tags.MINUTES_ON_INTERACTIVE_EXERCISES = minutesOnInteractiveExercises;
  }

  return tags;
};

// this will replace for all the products
export const getSubscriptionFeeTags = (
  questionnaire: QuestionnaireModel,
  config: ConfigModel,
  state: StateModel,
  locale: LocaleType,
  products?: ProductModel[],
) => {
  const formatter = formatCurrency;

  const studySchedule = getStudySchedule(questionnaire, state);

  const tags: TagMap = {};
  if (config && products && studySchedule) {
    const { months } = studySchedule;

    // installment products
    const product = products.find((p) => p.productType === "subscription");
    if (product) {
      const { currency } = product;

      // numeric presentations
      const fee = getSubscriptionFee(product);
      const totalFee = getSubscriptionTotal(product, months);

      // string presentations
      const subscriptionFeeString = formatter(fee, currency, locale);
      const subscriptionFeeDiscount50String = formatter(
        fee * 0.5,
        currency,
        locale,
      );
      const subscriptionTotalFeeString = formatter(totalFee, currency, locale);

      // tag allocation
      tags.SUBSCRIPTION_FEE = subscriptionFeeString;
      tags.SUBSCRIPTION_FEE_DISCOUNT_50 = subscriptionFeeDiscount50String;
      tags.SUBSCRIPTION_TOTAL_FEE = subscriptionTotalFeeString;
    }
  }

  // fill the blanks
  tags.SUBSCRIPTION_FEE = tags.SUBSCRIPTION_FEE || "";
  tags.SUBSCRIPTION_FEE_DISCOUNT_50 = tags.SUBSCRIPTION_FEE_DISCOUNT_50 || "";
  tags.SUBSCRIPTION_TOTAL_FEE = tags.SUBSCRIPTION_TOTAL_FEE || "";

  return tags;
};

// NOTICE: these prices are always discounted, if a discount is active
export const getProductTags = (
  questionnaire: QuestionnaireModel,
  product: ProductModel,
  locale: LocaleType,
  studySchedule: StudyScheduleModel | undefined,
) => {
  const configuration = getProductConfiguration(questionnaire, product);
  const { discounts = [] } = configuration;
  const { currency } = product;

  // exit, if no study schedule known
  if (!studySchedule) {
    return {};
  }

  // deconstruct studySchedule
  const { months, weeks = 13 } = studySchedule;

  // calculate discounted prices
  const meFee = getSubscriptionFee(product);
  const meTotalFee = getProgramTotal(product, months);

  const discount = discounts.find((d) => d.currency === product.currency);

  // allocate tags
  // WARNING: DO NOT USE THERE TAGS IN GENERIC VIEWS
  const tags: TagMap = {
    ME_MONTHS: months,
    ME_MONTHS_PLUS_ONE: months + 1,
    ME_WEEKS_PLUS_ONE: weeks + 1,
    ME_FEE: formatCurrency(meFee, currency, locale),
    ME_FEE_PER_MONTH: formatCurrency(meFee / months, currency, locale),
    ME_FEE_PER_WEEK: formatCurrency(meFee / weeks, currency, locale),
    ME_DISCOUNT_PERCENTAGE: discount ? discount.percentage.toString() : "n/a",

    // NOTICE: this in unrounded value, that is intentional
    ME_TOTAL_FEE: formatCurrency(meTotalFee, currency, locale),
  };

  return tags;
};

export const getTargetLevelTags = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  translate: TranslateFn,
) => {
  const tags: TagMap = {};

  if (state) {
    // find user level question
    const targetLevelObject = getTargetLevelSelection(questionnaire, state);
    if (targetLevelObject) {
      const { targetLevel = "" } = targetLevelObject;

      tags.TARGET_LEVEL = translate(targetLevel).toLocaleLowerCase();
    }
  }

  return tags;
};
export const getInitialLevelTags = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  translate: TranslateFn,
) => {
  const tags: TagMap = {};

  if (state) {
    // find user level question
    const initialLevelObject = getInitialLevelSelection(questionnaire, state);
    if (initialLevelObject) {
      const { initialLevel = "", initialLevelText = "" } = initialLevelObject;

      tags.INITIAL_LEVEL = translate(initialLevel);
      tags.INITIAL_LEVEL_TEXT = translate(initialLevelText);
    }
  }

  return tags;
};

export const getUserTags = (state?: StateModel) => {
  const tags: TagMap = {};

  // parse email and firstname
  const email = state?.user?.email || read<string>(SIGNUP_EMAIL_KEY) || "n/a";
  const firstname =
    state?.user?.firstname || read<string>(SIGNUP_NAME_KEY) || "n/a";

  // tag allocation
  tags.EMAIL = email;
  tags.FIRSTNAME = firstname;

  return tags;
};

const getMonth = (months: number) => {
  const month = dayjs().add(months, "months").subtract(1, "day").month();
  return getMonthName(month);
};

export const getMonthTags = () => {
  const tags: TagMap = {
    "3_MONTHS_LATER": getMonth(3),
    "6_MONTHS_LATER": getMonth(6),
    "9_MONTHS_LATER": getMonth(9),
  };
  return tags;
};

export const composeTags = (
  translate: TranslateFn,
  questionnaire: QuestionnaireModel,
  state: StateModel,
  config: ConfigModel,
  locale: LocaleType,
  products?: ProductModel[],
) => {
  const tags: TagMap = {
    ...getUserTags(state),
    ...getStudyScheduleTags(questionnaire, state, translate),
    ...getMonthTags(),
    ...getInitialLevelTags(questionnaire, state, translate),
    ...getTargetLevelTags(questionnaire, state, translate),
    ...getSubscriptionFeeTags(questionnaire, config, state, locale, products),
  };
  return tags;
};
