import { requiredValidator, runValidations, ValidatorFn } from "lib/validators";
import { ContentSchema } from "../factory";
import SignupNameContent from "./signup-name";
import { ValidatorMethod } from "../../validations";

const names = ["Dave", "Bob", "Julia", "Dorothy", "John", "Emily"];

export const generateRandomName = () =>
  names[Math.floor(Math.random() * names.length)];

export const signupNameContentValidator: ValidatorMethod = (state, t) => {
  const { user } = state;
  const { firstname } = user;

  // create validation
  const validators: ValidatorFn[] = [requiredValidator(t)];
  const validationResult = runValidations(firstname, validators);

  if (validationResult) {
    return "fail";
  }

  return "pass";
};

export const signupNameSchema: ContentSchema = {
  Component: SignupNameContent,
  validator: signupNameContentValidator,
};
