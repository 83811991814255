import { createContext, FunctionComponent, useEffect, useState } from "react";
import { DayJSLocaleMap, TranslationModel } from "models/locale";

import dayjs from "dayjs";
import { usePageLocale } from "hooks/route";

// create context
export const TranslationContext = createContext<TranslationModel | undefined>(
  undefined,
);

const TranslationProvider: FunctionComponent<any> = ({ children }) => {
  // defaults
  const [translations, toggleTranslations] = useState<
    TranslationModel | undefined
  >(undefined);
  const locale = usePageLocale();

  useEffect(() => {
    const load = async () => {
      // switch dayjs, use interval to make sure we have loaded the correct one
      const dayJsLocale = DayJSLocaleMap[locale];
      const interval = setInterval(() => {
        const localeChangedTo = dayjs.locale(dayJsLocale);
        if (localeChangedTo === dayJsLocale) {
          // the locale is the same, change success
          clearInterval(interval);
        }
      }, 5);

      // toggle translations
      let langPack: TranslationModel;
      switch (locale) {
        case "es-419":
          langPack = (await import("translations/es-419.json")).default;
          break;
        case "es-ES":
          langPack = (await import("translations/es-ES.json")).default;
          break;
        case "en-US":
          langPack = (await import("translations/en-US.json")).default;
          break;
        case "pt-BR":
          langPack = (await import("translations/pt-BR.json")).default;
          break;
        case "it-IT":
          langPack = (await import("translations/it-IT.json")).default;
          break;
        case "de-DE":
          langPack = (await import("translations/de-DE.json")).default;
          break;
      }
      toggleTranslations(langPack);
    };
    load();
  }, [locale]);

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
