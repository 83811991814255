import Description from "components/description";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useLink, usePageLocale, useQuestionnaireLink } from "hooks/route";
import { useTranslations } from "hooks/translations";
import { getControlQuestionnaireVersion } from "lib/questionnaire";
import { read } from "lib/storage";
import { breakAll, replaceTags } from "lib/string";
import { QuestionnaireVersion, VERSION_KEY } from "models/questionnaire";
import { SIGNUP_EMAIL_KEY } from "models/user";
import { useNavigate } from "react-router";

const LoginFailureView = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const locale = usePageLocale();

  const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);
  const cachedVersion = read<QuestionnaireVersion>(VERSION_KEY);
  const version = cachedVersion || getControlQuestionnaireVersion(locale);
  const url = useQuestionnaireLink(version);
  const loginLink = useLink("login-link");

  const handleRetry = async () => {
    navigate(loginLink);
  };

  const handleCreate = () => {
    navigate(url);
  };

  const description = replaceTags(t("email_error_body"), {
    EMAIL: breakAll(cachedEmail || ""),
  });

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("email_error_button1"),
        type: "primary",
        onClick: handleRetry,
        href: loginLink,
      },
    },
    {
      type: "button",
      button: {
        text: t("email_error_button2"),
        type: "link",
        onClick: handleCreate,
        href: url,
      },
    },
  ];

  return (
    <View
      title={t("email_error_title")}
      description={t("login_body")}
      showLogoInFooter
      cover
    >
      <Navbar />
      <ContentContainer>
        <Title>{t("email_error_title")}</Title>
        <Description>{description}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default LoginFailureView;
