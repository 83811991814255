import { Fragment, FunctionComponent } from "react";
import { ContentProps } from "../factory";
import { ScheduleSummaryContentType } from "models/content/schedule-summary";
import {
  StyledIcon,
  StyledItem,
  StyledLabel,
  StyledScheduleSummary,
  StyledText,
} from "./schedule-summary.styles";
import { replaceTags } from "lib/string";
import { useMedia } from "hooks/media";

interface ScheduleSummaryContentProps extends ContentProps {
  content: ScheduleSummaryContentType;
}

const ScheduleSummaryContent: FunctionComponent<
  ScheduleSummaryContentProps
> = ({ tags }) => {
  const monthsIcon = useMedia("icons/CalendarIcon", "mobile", "png");
  const monthsLabel = replaceTags("#MONTHS# meses", tags);

  const sessionsIcon = useMedia("icons/SunIcon", "mobile", "png");
  const sessionsLabel = replaceTags(
    "#PRACTICE_DAYS_PER_WEEK# sesiones / semana",
    tags,
  );

  const minutesIcon = useMedia("icons/ClockIcon", "mobile", "png");
  const minutesLabel = replaceTags("#MINUTES_PER_SESSION# min", tags);

  return (
    <Fragment>
      <StyledScheduleSummary>
        <StyledText>Completarás tu programa en:</StyledText>
        <StyledItem>
          <StyledIcon src={monthsIcon} />
          <StyledLabel>{monthsLabel}</StyledLabel>
        </StyledItem>
        <StyledItem>
          <StyledIcon src={sessionsIcon} />
          <StyledLabel>{sessionsLabel}</StyledLabel>
        </StyledItem>
        <StyledItem>
          <StyledIcon src={minutesIcon} />
          <StyledLabel>{minutesLabel}</StyledLabel>
        </StyledItem>
      </StyledScheduleSummary>
      <div />
    </Fragment>
  );
};

export default ScheduleSummaryContent;
