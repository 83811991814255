import { useQuestionnaire } from "hooks/questionnaire";
import { FunctionComponent, useEffect, useState } from "react";
import { ContentProps } from "../factory";
import {
  StyledBeginMonth,
  StyledEndMonth,
  StyledChartContainer,
  StyledMonthNumber,
  StyledMonths,
  StyledProgramLength,
  StyledProgramPromiseContent,
  StyledChartContainerTablet,
} from "./program-promise.styles";
import dayjs from "dayjs";
import { getMonthNameShort } from "lib/date";
import { capitalize } from "lib/string";
import { useTranslations } from "hooks/translations";
import Lottie from "components/lottie";
import { loadLottieFile } from "lib/lottie";
import { getStudySchedule, getInitialLevelSelection } from "lib/question";
import { useQuestionnaireState } from "hooks/state";
import { ProgramPromiseContentType } from "models/content/program-promise";
import { useView } from "hooks/view";
import { useTheme } from "hooks/theme";
import { UserLevelType } from "models/questionnaire";
import { Device } from "theme/devices";
import { ViewType } from "models/view";
import { LocaleType } from "models/locale";
import { usePageLocale } from "hooks/route";

interface ProgramPromiseContentProps extends ContentProps {
  content: ProgramPromiseContentType;
}

type CollectionType = Partial<Record<Device, any>>;

const ProgramPromiseContent: FunctionComponent<ProgramPromiseContentProps> = ({
  content,
}) => {
  const [animationData, setAnimationData] = useState<CollectionType>({});
  const locale = usePageLocale();
  const questionnaire = useQuestionnaire();
  const theme = useTheme();
  const state = useQuestionnaireState();
  const view = useView();
  const userLevelObject = getInitialLevelSelection(questionnaire, state);
  const t = useTranslations();
  const { programPromiseOptions } = content;
  const studySchedule = getStudySchedule(questionnaire, state);

  // show range or not
  const range =
    programPromiseOptions && programPromiseOptions.range !== undefined
      ? programPromiseOptions.range
      : true;

  // use primarly props, secondarily studySchedule and if nothing else, default to 9
  let months = programPromiseOptions?.months;
  if (!months) {
    // rely on study schedule
    months = studySchedule ? studySchedule.months : 9;
  }

  const beginMonth = capitalize(getMonthNameShort(dayjs().month()));
  const endMonth = capitalize(
    getMonthNameShort(dayjs().add(months, "months").subtract(1, "day").month()),
  );

  useEffect(() => {
    const fn = async () => {
      console.info(userLevelObject);
      if (userLevelObject) {
        const userLevel =
          userLevelObject.initialLevelType || userLevelObject.userLevel;
        const animations = await loadAnimations(userLevel, view, locale);
        console.info(userLevel);
        setAnimationData(animations);
      }
    };
    fn();
  }, []);

  return (
    <StyledProgramPromiseContent>
      <StyledMonths>
        {range && (
          <StyledBeginMonth $theme={theme} $view={view}>
            {beginMonth}
          </StyledBeginMonth>
        )}
        <StyledProgramLength $view={view}>
          <StyledMonthNumber $view={view}>{months}</StyledMonthNumber>
          &nbsp;{t("months")}
        </StyledProgramLength>
        {range && (
          <StyledEndMonth $theme={theme} $view={view}>
            {endMonth}
          </StyledEndMonth>
        )}
      </StyledMonths>
      <StyledChartContainer>
        {animationData.mobile && (
          <Lottie animationData={animationData.mobile} />
        )}
      </StyledChartContainer>
      <StyledChartContainerTablet>
        {animationData.tablet && (
          <Lottie animationData={animationData.tablet} />
        )}
      </StyledChartContainerTablet>
    </StyledProgramPromiseContent>
  );
};

export default ProgramPromiseContent;

const loadAnimations = async (
  userLevel: UserLevelType,
  view: ViewType,
  locale?: LocaleType,
): Promise<CollectionType> => {
  const mobileParts = ["ProgressVisualisation", "Mobile"];
  const tabletParts = ["ProgressVisualisation", "Tablet"];

  if (view === "dark") {
    mobileParts.push("White");
    tabletParts.push("White");
  }

  // add userlevel
  const level = UserLevelMap[userLevel];
  mobileParts.push(`_${level}`);
  tabletParts.push(`_${level}`);

  // add locale
  if (locale) {
    mobileParts.push(`_${locale}`);
    tabletParts.push(`_${locale}`);
  }

  // Example: ProgressVisualisationMobileWhite_beginner
  return {
    mobile: await loadLottieFile(mobileParts.join("")),
    tablet: await loadLottieFile(tabletParts.join("")),
  };
};

const UserLevelMap: Partial<Record<UserLevelType, string>> = {
  beginner: "beginner",
  basic: "basic",
  intermediate: "intermediate",
  upperintermediate: "upperintermediate",
  advanced: "upperintermediate",
};
