import { StartHereImageContentType } from "models/content/start-here-image";
import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import StartHere from "components/questionnaire/start-here";

interface StartHereImageContentProps extends ContentProps {
  content: StartHereImageContentType;
}

const StartHereImageContent: FunctionComponent<
  StartHereImageContentProps
> = ({}) => {
  return <StartHere />;
};

export default StartHereImageContent;
