import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import TableContent from "./table";

export const tableContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const tableSchema: ContentSchema = {
  Component: TableContent,
  validator: tableContentValidator,
};
