import PaymentComponent from "components/payments/payment";
import { useQuestionnaire } from "hooks/questionnaire";
import { useProducts } from "hooks/products";
import { useQuestionnaireState } from "hooks/state";
import { write } from "lib/storage";
import { replaceTags } from "lib/string";
import { getProductTags } from "lib/tag";
import { ProductModel, PRODUCT_KEY } from "models/product";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentProps } from "../factory";
import { getStudySchedule } from "lib/question";
import Description from "components/description";
import {
  StyledBottomLine,
  StyledPayment,
  StyledPaymentLine,
  StyledTitle,
  StyledTopLine,
} from "./payment.styles";
import { getProductConfiguration } from "lib/product-configuration";
import { PaymentContentType } from "models/content/payment";
import { useView } from "hooks/view";
import { useTheme } from "hooks/theme";
import PriceFrame from "./price-frame";
import { useConfig } from "hooks/config";
import Products from "components/questionnaire/products";
import Price from "components/questionnaire/price";
import { useTranslations } from "hooks/translations";
import { usePageLocale } from "hooks/route";

interface PaymentContentProps extends ContentProps {
  content: PaymentContentType;
}

const PaymentContent: FunctionComponent<PaymentContentProps> = ({
  content,
  tags,
}) => {
  const config = useConfig();
  const [productId, toggleProductId] = useState<string | undefined>(undefined);
  const view = useView();
  const theme = useTheme();
  const t = useTranslations();
  const locale = usePageLocale();

  // fetch prices
  const questionnaire = useQuestionnaire();
  const products = useProducts();
  const state = useQuestionnaireState();
  const studySchedule = getStudySchedule(questionnaire, state);

  const chooseProduct = async (product: ProductModel) => {
    const { productId } = product;

    toggleProductId(productId);

    // write to storage so we can use it later
    write<ProductModel>(PRODUCT_KEY, product);
  };

  useEffect(() => {
    if (!products) {
      return;
    }

    // choose first product (should always exist)
    chooseProduct(products[0]);

    // revert scroll
    window.scrollTo(0, 0);
  }, [products]);

  // do not continue without a product
  if (!productId || !products) {
    return null;
  }

  // find product
  const product = products.find((p) => p.productId === productId);
  if (!product) {
    throw new Error("No product found!");
  }

  const modifiedTags = {
    ...tags,
    ...getProductTags(questionnaire, product, locale, studySchedule),
  };

  // fetch newer versions of the texts
  const {
    paymentTexts = {},
    paymentOptions = { noConfiguration: false, productSelector: "radio" },
  } = content;
  const title = t(paymentTexts.title || "");
  const description = t(paymentTexts.description || "");

  const replace = (text: string) => {
    return replaceTags(text, modifiedTags);
  };

  // get product configuration
  const configuration = paymentOptions.noConfiguration
    ? undefined
    : getProductConfiguration(questionnaire, product);

  // paymentHeader
  const paymentHeader = t(
    configuration?.paymentTexts?.paymentHeader ||
      paymentTexts.paymentHeader ||
      "",
  );

  // derive typeText from product type
  let typeText = t(
    configuration?.paymentTexts?.typeText || paymentTexts.typeOnetime || "",
  );
  if (product.productType === "subscription") {
    typeText = t(
      configuration?.paymentTexts?.typeText ||
        paymentTexts.typeSubscription ||
        "",
    );
  }

  // if argentina, add more
  // TODO: this should be done some structure in JSON...
  if (config.country === "AR") {
    typeText = `Impuestos ajenos a Master English pueden ser aplicados a usuarios de Argentina, de acuerdo a los impuestos PAÍS y WTH. Estos impuestos pueden ser equivalentes al 100% del valor de cada transacción. Puedes solicitar un reembolso a las autoridades tributarias, ya que Master English es un software educativo.<br/><br/>${typeText}`;
  }

  // paymentFooter
  const paymentFooter = t(
    configuration?.paymentTexts?.paymentFooter ||
      paymentTexts.paymentFooter ||
      "",
  );

  // RENDERING ORDER
  // 1. title
  // 2. description
  // 3. ProductSelection
  // 4. typeText
  // 5. <Line/> + paymentHeader
  // 6. <PaymentComponent/>
  // 7. paymentFooter

  const { productSelector = "radio", badge } = paymentOptions;

  return (
    <StyledPayment>
      {view === "light" && <StyledTopLine $theme={theme} $view={view} />}
      {title && (
        <StyledTitle $view={view} $theme={theme}>
          {replace(title)}
        </StyledTitle>
      )}
      {description && <Description>{replace(description)}</Description>}
      {productSelector === "single" && <Price product={product} />}
      {productSelector === "single-frame" && (
        <PriceFrame product={product} badge={badge} />
      )}
      {productSelector === "radio" && (
        <Products
          product={product}
          products={products}
          onSelect={chooseProduct}
        />
      )}
      <Description>{replace(typeText)}</Description>
      {paymentHeader && (
        <Fragment>
          <StyledPaymentLine $theme={theme} $view={view} />
          <StyledTitle $view={view} $theme={theme}>
            {replace(paymentHeader)}
          </StyledTitle>
        </Fragment>
      )}
      <PaymentComponent product={product} />
      {paymentFooter && <Description>{replace(paymentFooter)}</Description>}
      <StyledBottomLine $theme={theme} $view={view} />
    </StyledPayment>
  );
};

export default PaymentContent;
