import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { checkRequirements } from "lib/requirement";
import { ParagraphsContentType } from "models/content/paragraph";
import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import BulletList from "components/questionnaire/bullet-list";

interface ParagraphsContentProps extends ContentProps {
  content: ParagraphsContentType;
}

const ParagraphsContent: FunctionComponent<ParagraphsContentProps> = ({
  content,
  tags,
}) => {
  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const { paragraphs } = content;

  const filteredParagraphs = paragraphs.filter((pg) => {
    const { requirements } = pg;

    // requirements
    if (requirements) {
      return checkRequirements({ state, requirements, config, questionnaire });
    }

    // no requirements at all
    return true;
  });

  return <BulletList paragraphs={filteredParagraphs} tags={tags} />;
};

export default ParagraphsContent;
