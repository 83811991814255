import Table from "components/table/table";
import TableCell from "components/table/table-cell";
import TableHeader from "components/table/table-header";
import TableRow from "components/table/table-row";
import { StyledLogo, StyledTableIcon } from "components/table/table.styles";
import {
  TableContentCell,
  TableContentHeader,
  TableContentType,
} from "models/content/table";
import { Fragment, FunctionComponent } from "react";
import { ContentProps } from "../factory";
import { toHTML } from "lib/string";
import { useTheme } from "hooks/theme";
import { StyledIcon } from "./table.styles";
import { useTranslations } from "hooks/translations";
import { parseMediaUrl, useMedia } from "hooks/media";

interface TableContentProps extends ContentProps {
  content: TableContentType;
}

const TableContent: FunctionComponent<TableContentProps> = ({ content }) => {
  const theme = useTheme();
  const t = useTranslations();
  const { tableOptions } = content;
  const { columns, rows } = tableOptions;
  const tickIcon = useMedia("icons/CompleteTick_Basic", "mobile", "svg");
  const tickDisabledIcon = useMedia("icons/Tickless_Disabled", "mobile", "svg");
  const logo = useMedia("icons/Logo_Mark", "mobile", "svg");

  const renderCell = (cell: TableContentCell, cellIndex: number) => {
    const { type, content = "", align = "center" } = cell;
    return (
      <TableCell key={cellIndex} align={align}>
        {/* this should be deprecated */}
        {type === "title" && toHTML(t(content))}
        {type === "text" && toHTML(t(content))}
        {type === "positive-icon" && (
          <StyledTableIcon $theme={theme} src={tickIcon}></StyledTableIcon>
        )}
        {type === "negative-icon" && (
          <StyledTableIcon
            $theme={theme}
            src={tickDisabledIcon}
          ></StyledTableIcon>
        )}
      </TableCell>
    );
  };

  const renderIcon = (icon: string) => {
    const url = parseMediaUrl(`icons/${icon}`, "mobile", "png");
    return <StyledIcon src={url} />;
  };

  const renderHeaderContent = (column: TableContentHeader) => {
    const { type, icon, content = "" } = column;
    if (type === "logo") {
      return <StyledLogo src={logo} />;
    }

    return (
      <Fragment>
        {icon && renderIcon(icon)}
        {toHTML(t(content))}
      </Fragment>
    );
  };

  return (
    <Table>
      <TableRow>
        {columns.map((column, columnIndex) => {
          const { align = "center", small = false } = column;
          return (
            <TableHeader
              width={column.width}
              key={columnIndex}
              align={align}
              small={small}
            >
              {renderHeaderContent(column)}
            </TableHeader>
          );
        })}
      </TableRow>
      {rows.map((row, index) => {
        return (
          <TableRow key={index}>
            {row.cells.map((cell, cellIndex) => renderCell(cell, cellIndex))}
          </TableRow>
        );
      })}
    </Table>
  );
};

export default TableContent;
