import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import DaySelectionConfirmContent from "./day-selection-confirm";

export const daySelectionConfirmContentValidator: ValidatorMethod = () => {
    return "pass";
  };
  
  export const daySelectionConfirmSchema: ContentSchema = {
    Component: DaySelectionConfirmContent,
    validator: daySelectionConfirmContentValidator,
  };
  