import { ConfigModel } from "models/config";
import { QuestionnaireModel, QuestionnaireVersion } from "models/questionnaire";
import { StateModel } from "models/state";
import {
  TOKEN,
  REFRESH_TOKEN,
  SIGNUP_EMAIL_KEY,
  SIGNUP_NAME_KEY,
} from "models/user";
import { LoginResponseModel, refreshTokens } from "services/user";
import { getQuestionById } from "./questionnaire";
import { parseNextQuestion } from "./question";
import { write } from "./storage";

interface Props {
  questionnaire: QuestionnaireModel;
  state: StateModel;
  version: QuestionnaireVersion;
  baseLink: string;
}
export const getNextUrl = (props: Props) => {
  const { questionnaire, state, version, baseLink } = props;

  // here the currentQuestionId is the id of the last saved question
  const { currentQuestionId, selectedOptions } = state;

  // get the question and answer for the "last saved question"
  const question = getQuestionById(questionnaire, currentQuestionId);
  const so = selectedOptions.find((so) => so.questionId === currentQuestionId);
  if (!question || !so) {
    return `${baseLink}${version}/${currentQuestionId}/`;
  }

  // find option
  const { options = [] } = question;
  const option = options.find((o) => o.id === so.optionId);
  if (!option) {
    return `${baseLink}${version}/${currentQuestionId}/`;
  }

  // finally, apply logic and get us the correct id
  const questionId = parseNextQuestion(questionnaire, question, option, state);
  return `${baseLink}${version}/${questionId}/`;
};

interface RestoreSessionProps {
  config: ConfigModel;
  updateConfig: (newConfig: ConfigModel) => void;
  tempToken: string;
}

export const restoreSession = async (props: RestoreSessionProps) => {
  const { tempToken, config, updateConfig } = props;

  const response = await refreshTokens(tempToken);
  const { token, refreshToken, user } = response;

  // store new tokens to sessionStorage
  write(TOKEN, token);
  write(REFRESH_TOKEN, refreshToken);

  // store user email and firstname for further usage (in case we skip signup for example)
  write<string>(SIGNUP_EMAIL_KEY, user.email);
  write<string>(SIGNUP_NAME_KEY, user.firstname);

  // modify config, persist to state and return
  if (!user.uuid) {
    throw new Error("No user.uuid found!");
  }

  // set some stuff from the new config
  config.uuid = user.uuid;
  config.questionnaireVersion = parseQuestionnaireVersion(response);
  config.completed = parseQuestionnaireCompleted(response);
  config.entitlementExists = parseEntitlementExists(response);

  console.info(
    `Set uuid => ${config.uuid}, questionnaireVersion => ${config.questionnaireVersion}, completed = ${config.completed}`,
  );
  updateConfig(config);
};

export const parseQuestionnaireCompleted = (response: LoginResponseModel) => {
  const { features } = response;
  return features.questionnaire.completed || false;
};

export const parseEntitlementExists = (response: LoginResponseModel) => {
  const { features } = response;
  return features.questionnaire.entitlementExists || false;
};

export const parseQuestionnaireVersion = (
  response: LoginResponseModel,
): QuestionnaireVersion => {
  const { features } = response;
  return features.questionnaire.version;
};
