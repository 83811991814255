import { MediaSchema } from "lib/media";
import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import AppRatingContent from "./app-rating";

export const appRatingContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const appRatingSchema: ContentSchema = {
  Component: AppRatingContent,
  validator: appRatingContentValidator,
};

export const parseAppRatingMediaSchema = () => {
  const schema: MediaSchema = {
    filename: "GoldenStars",
    extension: "png",
    tablet: true,
  };
  return schema;
};
