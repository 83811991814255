import { Fragment } from "react";
import Router from "routes/router";
import GTagManager from "components/external/google-tag-manager";
import OneTrust from "components/external/onetrust";

const App = () => {
  return (
    <Fragment>
      {/* these will be in <head /> */}
      {import.meta.env.VITE_PUBLIC_NAME !== "production" && (
        <meta name="robots" content="noindex,follow" />
      )}

      {/* OneTrust */}
      <OneTrust />

      {/* load gtm, goes to <head /> */}
      <GTagManager />

      {/* main thing, goes to <body /> */}
      <Router />
    </Fragment>
  );
};

export default App;
