import { MediaSchema } from "lib/media";
import styled, { css } from "styled-components";
import devices from "theme/devices";

export const parseBubbleImageSchema = (filename: string): MediaSchema => {
  const schema: MediaSchema = {
    filename: `bubble/${filename}`,
    extension: "png",
  };
  return schema;
};

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;
  z-index: 99;
`;

export const ImageCSS = css`
  width: 124px;
  height: 124px;
  margin: 0 auto;

  margin-top: 2.25rem;
  margin-bottom: 2rem;

  @media ${devices.mobile} {
    margin-top: 1.75rem;
    margin-bottom: 2.2rem;
  }

  @media ${devices.tablet} {
    width: 180px;
    height: 180px;
    margin-top: 5rem;
  }

  @media ${devices.desktop} {
    margin-top: 7rem;
  }
`;
