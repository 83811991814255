import Image from "components/image";
import { useQuestion } from "hooks/question";
import { FunctionComponent } from "react";
import {
  parseWallpaperMediaSchema,
  StyledGradient,
  StyledWallpaper,
  WallpaperCSS,
} from "./wallpaper.styles";

const Wallpaper: FunctionComponent = () => {
  const question = useQuestion();
  const { wallpaper, navbar } = question;

  if (!wallpaper) {
    return null;
  }

  const { image, gradient = false } = wallpaper;
  const schema = parseWallpaperMediaSchema(wallpaper);

  return (
    <StyledWallpaper lowered={navbar !== undefined}>
      <Image schema={schema} alt={image} css={WallpaperCSS} />
      {gradient && <StyledGradient />}
    </StyledWallpaper>
  );
};

export default Wallpaper;
