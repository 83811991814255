import { read } from "lib/storage";
import { ContentSchema } from "../factory";
import ProofProgress from "./proof-progress";
import { ProofProgressContentType } from "models/content/proof-progress";
import { ValidatorMethod } from "../../validations";

export const proofProgressContentValidator: ValidatorMethod = (
  _state,
  _t,
  content,
) => {
  const { proofProgressOptions = {} } = content as ProofProgressContentType;
  const { animation = true } = proofProgressOptions;

  // if animated, pass when reached 100%
  if (animation === true) {
    const counter = read<number>(CUSTOM_KEY) || 0;
    return counter >= 100 ? "pass" : "fail";
  }

  // non-animated case
  return "pass";
};

export const proofProgressSchema: ContentSchema = {
  Component: ProofProgress,
  validator: proofProgressContentValidator,
};

export const CUSTOM_KEY = "proofCounter";
export const SPEED = 100;
