import { FunctionComponent } from "react";
import HelmetWrapper from "./helmet";
import { useMedia } from "hooks/media";

interface SharedHeadProps {
  title?: string;
  description?: string;
  image?: string;
}

const DEFAULT = "Master English";

const SharedHead: FunctionComponent<SharedHeadProps> = ({
  title = DEFAULT,
  description = "",
  image,
}) => {
  const defaultOg = useMedia("OG", "mobile", "jpg");
  const ogImgSrc = image || defaultOg;
  const ogImgFullUrl = `${ogImgSrc}`;

  return (
    <HelmetWrapper>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImgFullUrl} />
    </HelmetWrapper>
  );
};

export default SharedHead;
