import { TranslationModel } from "models/locale";

// this is used in useTranslate() hook but can be used standalone, if we need translations for known language
export const translate = (key: string, translations?: TranslationModel) => {
  // translations are not loaded yet (probably)
  if (!translations) {
    return "";
  }

  // if the translation doesn't exit, return key
  const translation = translations[key];
  if (!translation) {
    return key;
  }
  return translation;
};
