import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Progress from "components/questionnaire/progress";
import Transition from "components/transition";
import { useConfig } from "hooks/config";
import { useQuestion } from "hooks/question";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { useTheme } from "hooks/theme";
import {
  calculateUserLevel,
  getQuestionDescription,
  getQuestionTitle,
} from "lib/question";
import Tiles from "components/tiles";
import QuestionnaireContent from "./content";
import Wallpaper from "components/questionnaire/wallpaper";
import Header from "components/questionnaire/header";
import Preload from "components/questionnaire/preload";
import { Fragment } from "react";
import { StyledBadge } from "./questionnaire-view.styles";
import { useTranslations } from "hooks/translations";
import { getInitialQuestion } from "lib/questionnaire";
import QuickNavigation from "./quick-navigation";

const QuestionnaireQuestionView = () => {
  const config = useConfig();
  const theme = useTheme();
  const questionnaire = useQuestionnaire();
  const question = useQuestion();
  const state = useQuestionnaireState();
  const translate = useTranslations();

  const {
    footer = false,
    progress = false,
    dark = false,
    navbar = true,
    tiles = false,
    badge,
  } = question;

  console.info(`userLevel = ${calculateUserLevel(questionnaire, state)}`);
  // get first title and description
  const title = getQuestionTitle(questionnaire, question, state, config);
  const description = getQuestionDescription(
    questionnaire,
    question,
    state,
    config,
  );

  let { type: progressType = "top" } = questionnaire.progress;
  if (theme === "MasterEnglish") {
    // force navbar if ME theme
    progressType = "navbar";
  }

  const isInitial = question.id === getInitialQuestion(questionnaire).id;

  return (
    <Fragment>
      <View
        title={translate(title)}
        description={translate(description)}
        cover
        view={dark ? "dark" : "light"}
        footer={footer}
      >
        {navbar && (
          <Navbar
            links={false}
            logoAsLink={false}
            progress={progressType === "navbar" && progress}
            localeDropdown={isInitial}
          />
        )}
        {progress && progressType === "top" && <Progress />}

        <Transition questionId={question.id}>
          {badge && <StyledBadge filename={badge} />}
          <Wallpaper />
          <Header />
          <QuestionnaireContent />
          {tiles && <Tiles />}
        </Transition>
        <QuickNavigation />
      </View>
      <Preload question={question} />
    </Fragment>
  );
};

export default QuestionnaireQuestionView;
