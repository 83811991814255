import styled from "styled-components";

const Base = styled.div`
  position: fixed;
  bottom: 2rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: black;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  user-select: none;
`;

export const StyledNavigationLeft = styled(Base)`
  left: 2rem;
`;

export const StyledNavigationRight = styled(Base)`
  right: 2rem;
`;
