import { FunctionComponent } from "react";
import Image from "./image";
import { ImageCSS } from "./tiles.styles";
import { useTheme } from "hooks/theme";
import { MediaSchema } from "lib/media";

const Tiles: FunctionComponent = () => {
  const theme = useTheme();
  if (theme === "MasterEnglish" || theme === "HablaIngles") {
    return null;
  }

  const schema: MediaSchema = {
    filename: "Tile",
    extension: "png",
    tablet: true,
    desktop: false,
  };

  return <Image schema={schema} alt="Tiles" css={ImageCSS} />;
};

export default Tiles;
