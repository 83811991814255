import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import PaymentContent from "./payment";

export const paymentContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const paymentSchema: ContentSchema = {
  Component: PaymentContent,
  validator: paymentContentValidator,
};
