import { FunctionComponent, useEffect, useState } from "react";
import { PaddleConfigModel } from "models/paddle";
import { ProductModel } from "models/product";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { write } from "lib/storage";
import { PAYMENT_RETRY_URL_KEY } from "models/payment";
import { fetchPaylink } from "services/paylink";
import { getProgramPlan } from "lib/study-schedule";
import { getProgramView, getVersion } from "lib/questionnaire";
import { useDiscount } from "hooks/products";
import {
  usePaddleLocale,
  useQuestionLink,
  useStaticLink,
  useStudyLanguage,
} from "hooks/route";
import PaddleFrame from "./frame";
import { frameStyle } from "./frame.styles";

interface PaddleFrameProps {
  product: ProductModel;
}

const PaddleClassicFrame: FunctionComponent<PaddleFrameProps> = ({
  product,
}) => {
  const discount = useDiscount();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const [isLoading, toggleIsLoading] = useState(true);
  const version = getVersion(questionnaire);
  const pv = getProgramView(questionnaire, state);
  const programViewUrl = useQuestionLink(version, pv.id);
  const language = useStudyLanguage();
  const returnPath = useStaticLink(version, "post-purchase");
  const paddleLocale = usePaddleLocale();

  const initPaddle = (url: string) => {
    // create paddle config
    const paddleConfig: PaddleConfigModel = {
      locale: paddleLocale,
      method: "inline",
      override: url,
      frameTarget: "paddleFrame",
      frameStyle,
      loadCallback: () => {
        try {
          // remove our own spinner
          toggleIsLoading(false);
        } catch (_err) {
          // do nothing
        }
      },
    };

    // open paddle iframe
    const Paddle = (window as any).Paddle;
    Paddle.Checkout.open(paddleConfig);
  };

  useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;

    const init = async () => {
      // fetch paylink
      const paylink = await fetchPaylink({
        productId: product.productId,
        returnPath,
        paymentProvider: "paddle",
        language, // note this is the language to study, not the ui language/locale
        plan: getProgramPlan(questionnaire, state),
        couponCode: discount,
      });

      // also, create and store retry path
      write(PAYMENT_RETRY_URL_KEY, programViewUrl);

      // init paddle
      setTimeout(() => {
        initPaddle(paylink.url);
      }, 2500);

      // force remove spinner after 15sec
      spinnerTimeout = setTimeout(() => {
        toggleIsLoading(false);
      }, 15000);
    };

    init();

    // create an interval to watch if we need to cancel the payment
    return () => {
      clearTimeout(spinnerTimeout);
    };
  }, [product.productId]);

  return <PaddleFrame isLoading={isLoading} />;
};

export default PaddleClassicFrame;
