import { TestimonialContentType } from "models/content/testimonial";
import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import {
  StyledDash,
  StyledDetails,
  StyledName,
  StyledQuote,
  StyledTestimonial,
  StyledText,
  StyledCountry,
  StyledStars,
  StyledAuthor,
  StyledAuthorPicture,
  StyledAuthorDetails,
  StyledAuthorName,
  StyledAuthorCountry,
  StyledDisclaimer,
  StyledContainer,
} from "./testimonial.styles";
import { useTheme } from "hooks/theme";
import { useTranslations } from "hooks/translations";
import { useMedia } from "hooks/media";
import { usePageLocale } from "hooks/route";

interface TestimonialContentProps extends ContentProps {
  content: TestimonialContentType;
}

const TestimonialContent: FunctionComponent<TestimonialContentProps> = ({
  content,
}) => {
  const locale = usePageLocale();
  const translate = useTranslations();
  const theme = useTheme();
  const quote = useMedia("icons/quote", "mobile", "svg");
  const { testimonialOptions } = content;
  const { text, name, country, image } = testimonialOptions;
  const starsUrl = useMedia("yellow-rating-5-star", "mobile", "svg");
  const pictureUrl = useMedia(`testimonials/${image}`, "mobile", "png");

  const isTranslated =
    locale !== testimonialOptions.locale &&
    testimonialOptions.locale !== undefined;

  if (theme === "MasterEnglish") {
    return (
      <StyledContainer>
        <StyledTestimonial $theme={theme}>
          <StyledAuthor>
            <StyledAuthorPicture src={pictureUrl} />
            <StyledAuthorDetails>
              <StyledAuthorName>{translate(name)}</StyledAuthorName>
              <StyledAuthorCountry>{translate(country)}</StyledAuthorCountry>
            </StyledAuthorDetails>
          </StyledAuthor>
          <StyledText $theme={theme}>{translate(text)}</StyledText>
          <StyledStars src={starsUrl} />
        </StyledTestimonial>
        {isTranslated && (
          <StyledDisclaimer>
            [{translate(`translated_from_${testimonialOptions.locale}`)}]
          </StyledDisclaimer>
        )}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledTestimonial>
        <StyledQuote src={quote} />
        <StyledText>{translate(text)}</StyledText>
        <StyledDetails>
          <StyledDash>&mdash;</StyledDash>
          <StyledName>{translate(name)}</StyledName>,&nbsp;
          <StyledCountry>{translate(country)}</StyledCountry>
        </StyledDetails>
      </StyledTestimonial>
      {isTranslated && (
        <StyledDisclaimer>
          [{translate(`translated_from_${testimonialOptions.locale}`)}]
        </StyledDisclaimer>
      )}
    </StyledContainer>
  );
};

export default TestimonialContent;
