import Image from "components/image";
import { isLocal } from "lib/env";
import { toHTML } from "lib/string";
import { SlideshowContentType } from "models/content/slideshow";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentProps } from "../factory";
import {
  ImageCSS,
  StyledImageWrapper,
  StyledProgressContainer,
  StyledProgressOuter,
  StyledSlide,
  StyledText,
  StyledTextWrapper,
  StylesProgressInner,
} from "./slideshow.styles";
import { useTheme } from "hooks/theme";
import { useTranslations } from "hooks/translations";
import { parseSlideshowMediaSchema } from "./slideshow.utils";

const SKIP_ANIMATIONS = import.meta.env.VITE_SKIP_ANIMATIONS === "true";

interface SlideshowContentProps extends ContentProps {
  content: SlideshowContentType;
}

const SlideshowContent: FunctionComponent<SlideshowContentProps> = ({
  content,
  onSubmit,
}) => {
  const translate = useTranslations();
  const theme = useTheme();
  const [index, toggleIndex] = useState(0);
  const [active, toggleActive] = useState(false);
  const { slideshowOptions = {} } = content;
  const {
    size = "normal",
    progress = false,
    items = [],
    delay = isLocal() ? 2500 : 4500,
  } = slideshowOptions;

  useEffect(() => {
    let localIndex = 0;
    const interval = setInterval(() => {
      // inrease active
      localIndex += 1;

      if (localIndex === items.length) {
        // if reached end
        clearInterval(interval);

        // make a redirect
        onSubmit();

        // exit to prevent further animation
        return;
      }
      toggleIndex(localIndex);
    }, delay);

    // start progressbar
    toggleActive(true);

    // clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  const skip = () => {
    onSubmit();
  };

  return (
    <Fragment>
      <StyledImageWrapper $size={size}>
        {items.map((item, i) => {
          const schema = parseSlideshowMediaSchema(item);
          return (
            <StyledSlide key={item.image} opacity={index === i ? 1 : 0}>
              <Image schema={schema} alt="" css={ImageCSS} />
            </StyledSlide>
          );
        })}
        {progress && (
          <StyledProgressContainer $theme={theme}>
            <StyledProgressOuter $theme={theme}>
              <StylesProgressInner
                $theme={theme}
                $state={active}
                $duration={items.length * delay}
              />
            </StyledProgressOuter>
          </StyledProgressContainer>
        )}
      </StyledImageWrapper>
      <StyledTextWrapper $size={size}>
        {items.map((item, i) => {
          return (
            <StyledText
              key={item.text}
              $size={size}
              $opacity={index === i ? 1 : 0}
            >
              {toHTML(translate(item.text))}
            </StyledText>
          );
        })}
      </StyledTextWrapper>
      {SKIP_ANIMATIONS && <div onClick={skip}>SKIP SLIDESHOW</div>}
    </Fragment>
  );
};

export default SlideshowContent;
