import { MediaSchema } from "lib/media";
import {
  StyledImage,
  StyledLabel,
  StyledLocaleDesktop,
  StyledModal,
  StyledOption,
  StyledShim,
} from "./locale-desktop.styles";
import { Fragment, useState } from "react";
import { LocalePathMap, Locales, LocaleType } from "models/locale";
import { usePageLocale, useRoute, useRouteParams } from "hooks/route";
import { useNavigate } from "react-router";
import { useTranslations } from "hooks/translations";
import Switch from "components/form/switch";

const LocaleDesktop = () => {
  const route = useRoute();
  const params = useRouteParams();
  const navigate = useNavigate();
  const [isOpen, toggleIsOpen] = useState(false);
  const t = useTranslations();
  const locale = usePageLocale();

  const schema: MediaSchema = {
    filename: isOpen ? "locale_open" : "locale",
    tablet: false,
    desktop: false,
    extension: "svg",
  };

  const changeTo = (locale: LocaleType) => {
    if (route) {
      const { pathSets = {} } = route;
      const set = pathSets[locale];
      if (set) {
        let { path } = set;
        for (const key in params) {
          path = path.replace(`:${key}`, params[key] || "");
        }

        navigate(`/${LocalePathMap[locale]}/${path}`);
      }
    }
  };

  const renderModal = () => {
    return (
      <Fragment>
        <StyledShim onClick={() => toggleIsOpen(false)}>
          <StyledModal>
            {Locales.map((loc) => (
              <StyledOption key={loc} onClick={() => changeTo(loc)}>
                <Switch active={loc === locale} />
                <StyledLabel>{t(loc)}</StyledLabel>
              </StyledOption>
            ))}
          </StyledModal>
        </StyledShim>
      </Fragment>
    );
  };

  return (
    <StyledLocaleDesktop>
      <StyledImage
        schema={schema}
        onClick={async () => toggleIsOpen(true)}
        alt="Change language"
      />
      {isOpen && renderModal()}
    </StyledLocaleDesktop>
  );
};

export default LocaleDesktop;
