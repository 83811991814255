import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import { PaddleContentType } from "models/content/paddle";
import PaddleClassicFrame from "components/payments/frame/paddle-classic";
import { StyledLine, StyledPaddle } from "./paddle.styles";
import { useProducts } from "hooks/products";

interface PaddleContentProps extends ContentProps {
  content: PaddleContentType;
}

const PaddleContent: FunctionComponent<PaddleContentProps> = () => {
  const products = useProducts();

  if (!products) {
    return null;
  }

  return (
    <StyledPaddle>
      <StyledLine />
      <PaddleClassicFrame product={products[0]} />
    </StyledPaddle>
  );
};

export default PaddleContent;
