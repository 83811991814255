import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import GuaranteeContent from "./guarantee";

export const guaranteeContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const guaranteeSchema: ContentSchema = {
  Component: GuaranteeContent,
  validator: guaranteeContentValidator,
};
