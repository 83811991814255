import Image from "components/image";
import { ImageContentType } from "models/content/image";
import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import { ImageCSS, parseImageSchema } from "./image.styles";

interface ImageContentProps extends ContentProps {
  content: ImageContentType;
}

const ImageContent: FunctionComponent<ImageContentProps> = ({ content }) => {
  const { imageOptions } = content;
  const { alt } = imageOptions;

  const schema = parseImageSchema(imageOptions);

  return <Image schema={schema} alt={alt} css={ImageCSS} />;
};

export default ImageContent;
