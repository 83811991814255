import dayjs from "dayjs";
import { generateRandomName } from "./signup-name.utils";
import {
  emailValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { ContentSchema } from "../factory";
import SignupEmailContent from "./signup-email";
import { ValidatorMethod } from "../../validations";

export const generateRandomEmail = () => {
  const email = `${generateRandomName().toLowerCase()}+${dayjs().valueOf()}@worddive.com`;
  return email;
};

export const signupEmailContentValidator: ValidatorMethod = (state, t) => {
  const { user } = state;
  const { email } = user;

  // create validation
  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];
  const validationResult = runValidations(email, validators);

  if (validationResult) {
    return "fail";
  }

  return "pass";
};

export const signupEmailSchema: ContentSchema = {
  Component: SignupEmailContent,
  validator: signupEmailContentValidator,
};
