import List, { ListItemProps } from "components/list";
import { StyledListHeading } from "components/list.styles";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { checkRequirements } from "lib/requirement";
import { replaceTags } from "lib/string";
import { ListContentType } from "models/content/list";
import { Fragment, FunctionComponent } from "react";
import { ContentProps } from "../factory";
import { useTranslations } from "hooks/translations";

interface ListContentProps extends ContentProps {
  content: ListContentType;
}

const ListContent: FunctionComponent<ListContentProps> = ({
  content,
  tags,
}) => {
  const translate = useTranslations();

  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const { listItems = [], listHeading = "", listType = "normal" } = content;

  const items: ListItemProps[] = [];
  const filteredListItems = listItems.filter((item) => {
    const { requirements } = item;

    // requirements
    if (requirements) {
      return checkRequirements({ state, requirements, questionnaire, config });
    }

    // no requirements at all
    return true;
  });

  // create list items
  filteredListItems.forEach((item) => {
    const { number, title, text } = item;
    const taggedTitle = title ? replaceTags(translate(title), tags) : undefined;
    const taggedText = text ? replaceTags(translate(text), tags) : undefined;
    items.push({ title: taggedTitle, text: taggedText, number });
  });

  if (items.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {listHeading && (
        <StyledListHeading>{translate(listHeading)}</StyledListHeading>
      )}
      <List type={listType} items={items} />
    </Fragment>
  );
};

export default ListContent;
