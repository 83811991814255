import styled from "styled-components";
import devices from "theme/devices";
import Image from "components/image";
import { ColorCode } from "theme/colors";

export const StyledLocaleDesktop = styled.div`
  display: none;
  user-select: none;

  @media ${devices.desktop} {
    display: block;
    margin-right: 0rem;
  }
`;

export const StyledImage = styled(Image)`
  height: 20px;
  cursor: pointer;
`;

export const StyledModal = styled.div`
  background-color: ${ColorCode.WHITE};
  z-index: 999;
  position: fixed;
  padding: 1rem 2rem 1rem 1rem;
  right: 10px;
  top: 98px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  border-radius: 9px;
`;

export const StyledShim = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
`;

export const StyledLabel = styled.div``;
