import { CountryType, LocaleType } from "./locale";
import { QuestionnaireVersion } from "./questionnaire";

export const CONFIG_STATE_KEY = "config";

export interface ConfigModel {
  country: CountryType;
  uiLanguage: LocaleType;
  uuid: string;

  // feature flags
  questionnaireVersion?: QuestionnaireVersion;
  completed?: boolean;
  entitlementExists?: boolean;
}
