import { gtmJSException } from "lib/gtm";

export const reportException = (
  message: string,
  stack?: string,
  componentStack?: string,
) => {
  const { userAgent } = navigator;
  gtmJSException(message, userAgent, stack, componentStack);
};
