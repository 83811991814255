import { FunctionComponent } from "react";
import { StyledLogo, StyledLogos } from "./logos.styles";
import { useMedia } from "hooks/media";

const Logos: FunctionComponent = () => {
  const BreakthroughAward = useMedia("home/BreakthroughAward", "mobile", "png");
  const Patent = useMedia("home/Patent", "mobile", "png");

  return (
    <StyledLogos>
      <StyledLogo
        src={BreakthroughAward}
        alt="Breakthrough Award"
        fetchPriority="low"
      />
      <StyledLogo src={Patent} alt="Patent" fetchPriority="low" />
    </StyledLogos>
  );
};

export default Logos;
