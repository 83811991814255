import Radio from "components/form/radio";
import { FunctionComponent } from "react";
import { StyledBlue, StyledDiscountTag } from "components/form/radio.styles";
import { ProductModel } from "models/product";
import {
  StyledLargeText,
  StyledNormalText,
  StyledProducts,
  StyledSmallText,
} from "./products.styles";
import { useQuestionnaire } from "hooks/questionnaire";
import { replaceTags, toHTML } from "lib/string";
import { getProductTags, TagMap } from "lib/tag";
import { getStudySchedule } from "lib/question";
import { useQuestionnaireState } from "hooks/state";
import { getProductConfiguration } from "lib/product-configuration";
import { useTheme } from "hooks/theme";
import { useConfig } from "hooks/config";
import { useTranslations } from "hooks/translations";
import { usePageLocale } from "hooks/route";

interface ProductsProps {
  product: ProductModel;
  products: ProductModel[];
  onSelect: (product: ProductModel) => void;
}

const Products: FunctionComponent<ProductsProps> = ({
  product,
  products,
  onSelect,
}) => {
  const config = useConfig();
  const theme = useTheme();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const studySchedule = getStudySchedule(questionnaire, state);
  const t = useTranslations();
  const locale = usePageLocale();

  return (
    <StyledProducts>
      {products.map((p) => {
        const tags: TagMap = getProductTags(questionnaire, p, locale, studySchedule);

        const active = p.productId === product.productId;
        const trial = p.trialDays ? p.trialDays > 0 : false;

        // get configuration
        const configuration = getProductConfiguration(questionnaire, p);
        const { selectionTexts, discounts = [] } = configuration;

        // const header text
        const header = replaceTags(t(selectionTexts.header), tags);
        const price = replaceTags(t(selectionTexts.price), tags);

        // copy listPrice to price
        const listProduct: ProductModel = JSON.parse(JSON.stringify(p));
        if (listProduct.listPrice) {
          listProduct.price = listProduct.listPrice;
        }

        const discount = discounts.find((d) => d.currency === p.currency);
        return (
          <Radio key={p.productId} active={active} onSelect={() => onSelect(p)}>
            {discount && (
              <StyledDiscountTag>{toHTML(discount.tag)}</StyledDiscountTag>
            )}
            {trial && (
              <StyledNormalText $active={active} $theme={theme}>
                {t(selectionTexts.trial || "")}
              </StyledNormalText>
            )}
            <StyledNormalText $active={active} $theme={theme}>
              {trial && "+ "}
              {header}
            </StyledNormalText>
            <StyledLargeText $active={active} $theme={theme}>
              <StyledBlue $active={active} $theme={theme}>
                {price || "n/a"}{" "}
                {config.country === "AR" && (
                  <span style={{ display: "inline-block" }}>+ impuestos</span>
                )}
              </StyledBlue>
            </StyledLargeText>
            {configuration.selectionTexts.footer && (
              <StyledSmallText $active={active} $theme={theme}>
                {replaceTags(t(selectionTexts.footer || ""), tags)}
              </StyledSmallText>
            )}
          </Radio>
        );
      })}
    </StyledProducts>
  );
};

export default Products;
