import { useTheme } from "hooks/theme";
import { useView } from "hooks/view";
import { toHTML } from "lib/string";
import { Fragment, FunctionComponent } from "react";
import {
  ListType,
  StyledList,
  StyledListCheckmark,
  StyledListDot,
  StyledListItem,
  StyledListItemContent,
  StyledListItemText,
  StyledListItemTitle,
  StyledListNumber,
} from "./list.styles";
import { useMedia } from "hooks/media";

export interface ListItemProps {
  title?: string;
  text?: string;
  number?: number;
}

interface ListProps {
  className?: string;
  type?: ListType;
  items: ListItemProps[];
  classNameTitle?: string;
  classNameText?: string;
}

const List: FunctionComponent<ListProps> = ({
  className,
  items,
  type = "normal",
  classNameTitle,
  classNameText,
}) => {
  const view = useView();
  const theme = useTheme();
  const checkmark = useMedia("icons/blue-checkmark", "mobile", "svg");

  const renderType = (item: ListItemProps) => {
    const { number } = item;
    return (
      <Fragment>
        {type !== "none" && (
          <Fragment>
            {type === "checkmark" && (
              <StyledListCheckmark src={checkmark}></StyledListCheckmark>
            )}
            {type === "number" && (
              <StyledListNumber $theme={theme}>{number}</StyledListNumber>
            )}
            {type === "normal" && <StyledListDot $view={view} $theme={theme} />}
          </Fragment>
        )}
      </Fragment>
    );
  };

  const renderTitle = (item: ListItemProps) => {
    const { title } = item;
    return (
      <Fragment>
        {title && (
          <StyledListItemTitle
            $view={view}
            className={classNameTitle}
            $theme={theme}
          >
            {toHTML(title)}
          </StyledListItemTitle>
        )}
      </Fragment>
    );
  };

  const renderText = (item: ListItemProps) => {
    let { text } = item;

    if (theme === "MasterEnglish" && type === "number") {
      // in masterenglish theme the number is inlined
      text = `${item.number}. ${text}`;
    }

    return (
      <Fragment>
        {text && (
          <StyledListItemText
            $theme={theme}
            $view={view}
            className={classNameText}
          >
            {toHTML(text)}
          </StyledListItemText>
        )}
      </Fragment>
    );
  };

  return (
    <StyledList className={className}>
      {items.map((item, index) => {
        return (
          <StyledListItem
            key={`${index}-${item.title}-${item.text}`}
            $listType={type}
          >
            {renderType(item)}
            <StyledListItemContent>
              {renderTitle(item)}
              {renderText(item)}
            </StyledListItemContent>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};

export default List;
