import { FunctionComponent, useEffect, useState } from "react";
import { StyledCheckmark, StyledDot, StyledSwitch } from "./switch.styles";
import { useTheme } from "hooks/theme";
import { useMedia } from "hooks/media";

interface SwitchProps {
  active: boolean;
  checkmark?: boolean;
}

const Switch: FunctionComponent<SwitchProps> = ({
  active,
  checkmark = false,
}) => {
  const theme = useTheme();
  const [internal, toggleInternal] = useState<boolean>(false);
  const [animate, toggleAnimate] = useState(false);
  const [timeStamp] = useState(Date.now());
  const checkmarkSrc = useMedia("icons/blue-checkmark", "mobile", "svg");

  useEffect(() => {
    // 1000ms delay before starting any animations
    if (internal == false && active === true && Date.now() - timeStamp > 1000) {
      toggleAnimate(true);
      setTimeout(() => toggleAnimate(false), 500);
    }
    toggleInternal(active);
  }, [active, internal]);

  return (
    <StyledSwitch $active={active} $theme={theme}>
      {!checkmark && active && <StyledDot $animate={animate} />}
      {checkmark && active && (
        <StyledCheckmark $animate={animate} src={checkmarkSrc} />
      )}
    </StyledSwitch>
  );
};

export default Switch;
