import { LocalePathMap, LocaleType, PaddleLocaleMap } from "models/locale";
import { useLocation, useMatches } from "react-router";
import { useGeoLocale } from "./geo";
import definititions, { RouteHandle, RouteNameType } from "routes/definitions";
import { QuestionnaireVersion } from "models/questionnaire";
import { StaticViewEnum } from "models/question";

export const useRoute = () => {
  const match = useRouteMatch();

  if (match) {
    const handle = match.handle as RouteHandle;
    if (handle) {
      const { name } = handle;

      // find from routes
      const route = definititions.find((def) => def.name === name);
      if (route) {
        return route;
      }
    }
  }
};

export const useRouteMatches = () => {
  const matches = useMatches();
  return matches.filter((m) => m.handle !== undefined);
};

export const useRouteMatch = () => {
  const location = useLocation();

  const matches = useRouteMatches();
  for (let i = 0; i < matches.length; i += 1) {
    if (matches[i].pathname === location.pathname) {
      return matches[i];
    }
  }
};

export const useRouteParams = () => {
  const match = useRouteMatch();
  return match?.params;
};

export const usePageLocale = (): LocaleType => {
  const match = useRouteMatch();
  const geoLocale = useGeoLocale(); // the only place where is is actually used
  const location = useLocation();

  // special case as we can't reason the locale from the url
  if (location.pathname === "/") {
    return geoLocale;
  }

  // if we have a route match, dig locale from there
  if (match) {
    const handle = match.handle as RouteHandle;
    if (handle) {
      const { locale } = handle;
      if (locale) {
        return locale as LocaleType;
      }
    }
  }

  // use default from geo request
  return geoLocale;
};

export const usePaddleLocale = () => {
  const locale = usePageLocale();
  return PaddleLocaleMap[locale] || "es";
};

export const useLink = (
  routeName: RouteNameType,
  params?: Record<string, string>,
) => {
  const locale = usePageLocale();

  // default link
  let link = `/${LocalePathMap[locale]}`;

  // find the correct route
  const route = definititions.find((def) => def.name === routeName);
  if (route) {
    const { pathSets = {}, root = undefined } = route;
    if (root !== undefined) {
      // we have "root" defined in the definition
      link = `/${root}/`;
    } else {
      // normal route - add lang code and path and potentially trailing slash
      const set = pathSets[locale];
      if (set) {
        const { path } = set;

        // add a trailing slash, unless we are in the root
        if (path === "") {
          link = `${link}/${path}`;
        } else {
          link = `${link}/${path}/`;
        }
      }
    }
  }

  // replace params in the link
  for (const key in params) {
    link = link.replace(`:${key}`, params[key]);
  }

  return link;
};

export const useQuestionnaireLink = (version: QuestionnaireVersion) => {
  const link = useLink("questionnaire", { version });
  return link;
};

export const useQuestionLink = (
  version: QuestionnaireVersion,
  question: number,
) => {
  const link = useLink("question", { version, question: question.toString() });
  return link;
};

export const useStaticLink = (
  version: QuestionnaireVersion,
  staticView: StaticViewEnum,
) => {
  const link = useLink(`questionnaire-${staticView}`, { version });
  return link;
};

export const useRaffleLink = () => {
  const link = useLink("raffle");
  return link;
};

export const useStudyLanguage = () => "en-US";

export const useFullUrl = (path: string) => {
  const rootUrl = useRootUrl();
  return `${rootUrl}${path}`;
};

export const useRootUrl = () => {
  return `${import.meta.env.VITE_PUBLIC_ME_URL}`;
};
