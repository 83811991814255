import { MediaSchema } from "lib/media";
import { ImageOptions } from "models/content/image";
import { css } from "styled-components";
import { stretchToFullWidth } from "theme/mixins";

export const parseImageSchema = (imageOptions: ImageOptions) => {
  const { filename, extension = "jpg" } = imageOptions;

  const schema: MediaSchema = {
    filename,
    extension,
    tablet: true,
    desktop: true,
  };
  return schema;
};

export const ImageCSS = css`
  z-index: 60;
  width: 100%;
  ${stretchToFullWidth()};
  margin-bottom: 1.5rem;
`;
