import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import PreparingProgramSimpleContent from "./preparing-program-simple";

export const preparingProgramSimpleContentValidator: ValidatorMethod = () => {
  // always block, use onSubmit mechanism to trigger nav
  return "block";
};

export const preparingProgramSimpleSchema: ContentSchema = {
  Component: PreparingProgramSimpleContent,
  validator: preparingProgramSimpleContentValidator,
};
