import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ChatbotContent from "./chatbot";

export const chatbotContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const chatbotSchema: ContentSchema = {
  Component: ChatbotContent,
  validator: chatbotContentValidator,
};
