import { Fragment, useEffect, useState } from "react";
import Block from "components/layout/block";
import Section from "components/layout/section";
import Background from "components/layout/background";
import {
  createEmbeddedQuestionnaireState,
  getControlQuestionnaireVersion,
  getEmbeddedQuestionnaireVersion,
  getQuestionById,
} from "lib/questionnaire";
import Title from "components/title";
import { ActionType } from "components/layout/actions";
import { OptionModel } from "models/option";
import { StyledActionContainer } from "./embedded.styles";
import Description from "components/description";
import { useNavigate } from "react-router";
import { getQuestionDescription, getQuestionTitle } from "lib/question";
import { QuestionModel } from "models/question";

import { usePageLocale, useQuestionLink } from "hooks/route";
import { QuestionnaireModel } from "models/questionnaire";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { useTranslations } from "hooks/translations";

const EmbeddedQuestion = () => {
  const navigate = useNavigate();
  const t = useTranslations();
  const locale = usePageLocale();
  const [questionnaire, toggleQuestionnaire] = useState<
    QuestionnaireModel | undefined
  >(undefined);
  const url = useQuestionLink(
    questionnaire?.id || getControlQuestionnaireVersion(locale),
    3,
  );

  useEffect(() => {
    const fn = async () => {
      const q = await loadCleanQuestionnaire(
        "",
        getEmbeddedQuestionnaireVersion(locale),
        false,
        locale,
      );
      toggleQuestionnaire(q);
    };
    fn();
  }, [locale]);

  const handleNavigation = async (option: OptionModel) => {
    createEmbeddedQuestionnaireState(locale, undefined, undefined, option);
    navigate(url);
  };

  const renderQuestion = (q: QuestionnaireModel) => {
    const question = getQuestionById(q, 2) as QuestionModel;
    const title = t(getQuestionTitle(q, question));
    const description = t(getQuestionDescription(q, question));
    const actions = (question.options || []).map((option) => {
      return {
        type: "button",
        button: {
          text: t(option.description || ""),
          href: "",
          type: "default",
          onClick: () => handleNavigation(option),
        },
      } as ActionType;
    });
    return (
      <Fragment>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <StyledActionContainer actions={actions} />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Section>
        <Block order={1}>
          <Background
            align="left"
            filename="home/City"
            extension="jpg"
            priority="high"
          ></Background>
        </Block>
        <Block order={2}>
          {questionnaire && renderQuestion(questionnaire)}
        </Block>
      </Section>
    </Fragment>
  );
};

export default EmbeddedQuestion;
