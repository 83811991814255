import { ProductModel } from "models/product";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import PaddleClassicFrame from "./frame/paddle-classic";
import PaddleClassicSetup from "./setup/paddle-classic";
import PaddleBillingFrame from "./frame/paddle-billing";
import PaddleBllingSetup from "./setup/paddle-billing";
import { write } from "lib/storage";
import { PAYMENT_PROVIDER_KEY } from "models/payment";

interface PaymentComponentProps {
  product: ProductModel;
}

const PaymentComponent: FunctionComponent<PaymentComponentProps> = ({
  product,
}) => {
  const [loaded, toggleLoaded] = useState(false);
  const { paymentProvider = "paddle" } = product;

  useEffect(() => {
    console.info(`init paddle for product ${product.productId}`);
  }, [product.productId]);

  useEffect(() => {
    // write for further usage
    write(PAYMENT_PROVIDER_KEY, paymentProvider);
  }, [paymentProvider]);

  const renderSetup = () => {
    return (
      <Fragment>
        {paymentProvider === "paddle" && (
          <PaddleClassicSetup
            onLoad={() => toggleLoaded(true)}
            product={product}
          />
        )}
        {paymentProvider === "paddlebilling" && (
          <PaddleBllingSetup
            onLoad={() => toggleLoaded(true)}
            product={product}
          />
        )}
      </Fragment>
    );
  };

  const renderFrame = () => {
    return (
      <Fragment>
        {paymentProvider === "paddle" && (
          <PaddleClassicFrame product={product} />
        )}
        {paymentProvider === "paddlebilling" && (
          <PaddleBillingFrame product={product} />
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderSetup()}
      {loaded && renderFrame()}
    </Fragment>
  );
};

export default PaymentComponent;
