import { TranslateFn } from "models/locale";
import isEmail from "validator/lib/isEmail";
import isPhone from "validator/lib/isMobilePhone";

export type ValidatorFn = (value: string) => string | undefined;

export const phoneValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (
      !isPhone(String(value).toLowerCase(), undefined, { strictMode: true })
    ) {
      return t("validator_phone");
    }
    return undefined;
  };
};

export const requiredValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (value.length === 0) {
      return t("validator_required");
    }
    return undefined;
  };
};

export const emailValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (!isEmail(String(value).toLowerCase())) {
      return t("validator_invalid_email");
    }
    return undefined;
  };
};

export const passwordValidator = (t: TranslateFn) => {
  return (value: string) => {
    if (value.length < 8) {
      return t("validator_at_least_8");
    }
    return undefined;
  };
};

export const runValidations = (
  value: string,
  validations: ValidatorFn[],
): string | undefined => {
  for (let i = 0; i < validations.length; i += 1) {
    const result = validations[i](value);
    if (result !== undefined) {
      return result;
    }
  }
  return undefined;
};
