import { AppRatingContentType } from "./content/app-rating";
import { AppStoreButtonContentType } from "./content/app-store-button";
import { BubbleContentType } from "./content/bubble";
import { DaySelectionContentType } from "./content/day-selection";
import { DaySelectionConfirmContentType } from "./content/day-selection-confirm";
import { DescriptionContentType } from "./content/description";
import { FootnoteContentType } from "./content/footnote";
import { GuaranteeContentType } from "./content/guarantee";
import { HourSelectionContentType } from "./content/hour-selection";
import { ImageContentType } from "./content/image";
import { ListContentType } from "./content/list";
import { ParagraphsContentType } from "./content/paragraph";
import { PaymentContentType } from "./content/payment";
import { PreparingProgramContentType } from "./content/preparing-program";
import { PreparingProgramSimpleContentType } from "./content/preparing-program-simple";
import { ProgramPromiseContentType } from "./content/program-promise";
import { ProofProgressContentType } from "./content/proof-progress";
import { SignupEmailContentType } from "./content/signup-email";
import { SignupNameContentType } from "./content/signup-name";
import { SignupPasswordContentType } from "./content/signup-password";
import { SlideshowContentType } from "./content/slideshow";
import { StartHereImageContentType } from "./content/start-here-image";
import { StudyScheduleContentType } from "./content/study-schedule";
import { TableContentType } from "./content/table";
import { TestimonialContentType } from "./content/testimonial";
import { TitleContentType } from "./content/title";
import { WaitContentType } from "./content/wait";
import { PriceContentType } from "./content/price";
import { RequirementModel } from "./requirement";
import { SkillsToImproveContentType } from "./content/skills-to-improve";
import { MoreInfoOfContentType } from "./content/more-info-of";
import { ProgramChangeWishContentType } from "./content/program-change-wish";
import { PaddleContentType } from "./content/paddle";
import { ScheduleSummaryContentType } from "./content/schedule-summary";
import { ChatbotContentType } from "./content/chatbot";

export const SCROLL_DELAY = 2000;

export type ContentType = BaseType &
  (
    | TitleContentType
    | DescriptionContentType
    | FootnoteContentType
    | StudyScheduleContentType
    | SignupEmailContentType
    | SignupPasswordContentType
    | SignupNameContentType
    | DaySelectionContentType
    | DaySelectionConfirmContentType
    | HourSelectionContentType
    | ProgramPromiseContentType
    | ProofProgressContentType
    | ParagraphsContentType
    | ListContentType
    | SlideshowContentType
    | WaitContentType
    | PreparingProgramContentType
    | PreparingProgramSimpleContentType
    | GuaranteeContentType
    | PaymentContentType
    | AppRatingContentType
    | AppStoreButtonContentType
    | StartHereImageContentType
    | TableContentType
    | TestimonialContentType
    | ImageContentType
    | BubbleContentType
    | PriceContentType
    | SkillsToImproveContentType
    | MoreInfoOfContentType
    | ProgramChangeWishContentType
    | PaddleContentType
    | ScheduleSummaryContentType
    | ChatbotContentType
  );

type BaseType = {
  requirements?: RequirementModel;
};
