import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import StartHereImageContent from "./start-here-image";

export const startHereImageContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const startHereImageSchema: ContentSchema = {
  Component: StartHereImageContent,
  validator: startHereImageContentValidator,
};
