import { Fragment, FunctionComponent } from "react";
import { useConfig } from "hooks/config";
import { replaceTags, toHTML } from "lib/string";
import {
  ImageCSS,
  StyledAppRating,
  StyledAppRatingStars,
  StyledAppRatingText,
  StyledStars,
} from "./app-rating.styles";
import { ContentProps } from "../factory";
import Image from "components/image";
import { useView } from "hooks/view";
import { AppRatingContentType } from "models/content/app-rating";
import { useTheme } from "hooks/theme";
import { useTranslations } from "hooks/translations";
import { useMedia } from "hooks/media";
import { parseAppRatingMediaSchema } from "./app-rating.utils";

interface AppRatingContentProps extends ContentProps {
  content: AppRatingContentType;
}

const AppRatingContent: FunctionComponent<AppRatingContentProps> = ({
  content,
  tags,
}) => {
  const theme = useTheme();
  const config = useConfig();
  const view = useView();
  const translate = useTranslations();

  const { country } = config;
  const { appRatingTexts, appRatingStars = false } = content;

  const text = translate(
    appRatingTexts[country] || appRatingTexts["def"] || "n/a",
  );
  const description = replaceTags(text, tags);

  const schema = parseAppRatingMediaSchema();

  const starsUrl = useMedia("yellow-rating-5-star", "mobile", "svg");

  return (
    <StyledAppRating $theme={theme}>
      {theme === "MasterEnglish" && <StyledStars src={starsUrl} />}
      {appRatingStars && (
        <Fragment>
          {theme === "Kosmo" && (
            <StyledAppRatingStars>
              <Image schema={schema} alt="Golden Stars" css={ImageCSS} />
            </StyledAppRatingStars>
          )}
        </Fragment>
      )}
      <StyledAppRatingText $theme={theme} $view={view}>
        {toHTML(description)}
      </StyledAppRatingText>
    </StyledAppRating>
  );
};

export default AppRatingContent;
