import { request } from "lib/request";

interface Params {
  pricingId: string;
  couponCode?: string;
}

interface TransactionResponse {
  transactionId: string;
}

export const createTransaction = async (data: Params) => {
  const response = await request<TransactionResponse>({
    method: "POST",
    url: "/v1/create-transaction",
    data,
  });

  const { transactionId } = response.data;
  return transactionId;
};
