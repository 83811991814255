import { SlideShowItemModel } from "models/content/slideshow";
import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import SlideshowContent from "./slideshow";
import { MediaSchema } from "lib/media";

export const parseSlideshowMediaSchema = (item: SlideShowItemModel) => {
  const { image } = item;

  const schema: MediaSchema = {
    filename: `slideshow/${image}`,
    extension: "png",
    tablet: false,
    desktop: true,
    localised: true,
  };
  return schema;
};

export const slideshowContentValidator: ValidatorMethod = () => {
  return "block";
};

export const slideshowSchema: ContentSchema = {
  Component: SlideshowContent,
  validator: slideshowContentValidator,
};
