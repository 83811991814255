import { useConfig } from "hooks/config";
import { usePageLocale } from "hooks/route";
import { buildState } from "lib/state";
import { read, write } from "lib/storage";
import { STATE_KEY } from "models/questionnaire";
import { StateModel } from "models/state";
import { createContext, FunctionComponent, useEffect, useState } from "react";

interface StateContextInterface {
  state: StateModel;
  updateState: (newState: StateModel) => void;
}

export const StateContext = createContext<StateContextInterface | undefined>(
  undefined,
);

const getCachedState = () => {
  return read<StateModel>(STATE_KEY);
};

const StateProvider: FunctionComponent<any> = ({ children }) => {
  const locale = usePageLocale();
  const config = useConfig();

  const [state, toggleState] = useState<StateModel | undefined>(undefined);

  const updateState = (newState: StateModel) => {
    // update the local state
    toggleState({ ...newState });
    console.info(`Updated State Context`);

    // persist
    write(STATE_KEY, newState);
  };

  useEffect(() => {
    const init = async () => {
      console.info("StateProvider");

      // check if config exists
      const cachedState = getCachedState();
      if (cachedState) {
        updateState(cachedState);
        return;
      }

      // generate an empty one
      console.info(` ==> Context is empty, building new from config...`);
      updateState(buildState(locale, config));
    };

    init();
  }, []);

  if (!state) {
    return null;
  }

  return (
    <StateContext.Provider value={{ state, updateState }}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
