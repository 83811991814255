import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentProps } from "../factory";
import {
  StyledBarPositive,
  StyledBarNegative,
  StyledProgress,
  StyledChartWrapper,
  StyledWait,
} from "./proof-progress.styles";
import { write } from "lib/storage";
import { useTranslations } from "hooks/translations";
import Lottie from "components/lottie";
import { ProofProgressContentType } from "models/content/proof-progress";
import { loadLottieFile } from "lib/lottie";
import { SCROLL_DELAY } from "models/content";
import { CUSTOM_KEY, SPEED } from "./proof-progress.utils";
import { usePageLocale } from "hooks/route";

interface ProofProgressProps extends ContentProps {
  content: ProofProgressContentType;
}

const ProofProgress: FunctionComponent<ProofProgressProps> = ({
  onChange,
  content,
}) => {
  const locale = usePageLocale();
  const t = useTranslations();
  const [counter, setCounter] = useState(0);
  const [animationData, setAnimationData] = useState();
  const { proofProgressOptions = {} } = content;
  const { animation = true } = proofProgressOptions;

  useEffect(() => {
    // if we don't have animation, do not bother setting up the timers
    if (!animation) {
      return;
    }

    // make sure the validation is done correctly in the beginning
    let internalCounter = 0;
    write(CUSTOM_KEY, 0);
    onChange();

    // make sure the animation starts after SCROLL_DELAY
    let timer: NodeJS.Timeout;
    const initialTimer = setTimeout(() => {
      const schedule = () => {
        if (internalCounter < 100) {
          timer = setTimeout(() => {
            // increase counter
            internalCounter += 1;
            setCounter(internalCounter);

            // signal content level
            write(CUSTOM_KEY, internalCounter);
            onChange();

            // schedule next percentage
            schedule();
          }, SPEED);
        }
      };

      // initial scheudle
      schedule();

      // return a method to clean timeout
      return () => {
        clearTimeout(initialTimer);
        clearTimeout(timer);
      };
    }, SCROLL_DELAY);
  }, []);

  useEffect(() => {
    const fn = async () => {
      const filename = `ProofProgressVisualisation_${locale}`;
      const data = await loadLottieFile(filename);
      setAnimationData(data);
    };
    fn();
  }, []);

  const style = { clipPath: `inset(-2px -2px -2px ${counter}%)` }; // very nice hack, with clipPath we can change font color midletter

  return (
    <Fragment>
      <StyledChartWrapper>
        {animationData && <Lottie loop animationData={animationData} />}
      </StyledChartWrapper>
      {animation && counter < 100 && (
        <Fragment>
          <StyledWait text={t("calculating")} />
          <StyledProgress>
            <StyledBarPositive>{counter}%</StyledBarPositive>
            <StyledBarNegative speed={SPEED} style={style}>
              {counter}%
            </StyledBarNegative>
          </StyledProgress>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProofProgress;
