import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentProps } from "../factory";
import MultiSelection from "components/form/multi-selection";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { MoreInfoOfContentType } from "models/content/more-info-of";

interface MoreInfoOfContentProps extends ContentProps {
  content: MoreInfoOfContentType;
}

const MoreInfoOfContent: FunctionComponent<MoreInfoOfContentProps> = ({
  content,
  onChange,
}) => {
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  const [selected, toggleSelected] = useState<number[]>([]);
  const { moreInfoOfOptions } = content;
  const { items = [] } = moreInfoOfOptions;

  useEffect(() => {
    // always start from clean state, do not reuse browser state
  }, []);

  // compose options for the multiselection
  const options = items.map((skill, id) => {
    return {
      id,
      text: skill,
    };
  });

  const handleChoose = (id: number) => {
    if (selected.includes(id)) {
      toggleSelected([...selected.filter((s) => s !== id)]);
      state.moreInfoOf = [
        ...(state.moreInfoOf || []).filter((sd) => sd !== id),
      ];
    } else {
      const newSelected = [...selected, id].sort();
      toggleSelected(newSelected);
      state.moreInfoOf = newSelected;
    }

    // persist
    updateQuestionnaireState(state);

    // signal
    onChange();
  };

  return (
    <Fragment>
      <MultiSelection
        selected={selected}
        options={options}
        onChoose={handleChoose}
      />
    </Fragment>
  );
};

export default MoreInfoOfContent;
