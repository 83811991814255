import { os, name } from "platform";

export const OLD_OS_KEY = "oldOSWarning";

export enum PlatformType {
  ANDROID = "android",
  APPLE = "apple",
}

export const getPlatform = (): PlatformType => {
  if (os && os.family) {
    if (os.family.toLocaleLowerCase() === PlatformType.ANDROID) {
      return PlatformType.ANDROID;
    }
  }

  // default to 
  return PlatformType.APPLE;
};

const isDesktop = () => {
  if (os && os.family) {
    if (os.family === "OS X" || os.family === "Windows") {
      return true;
    }
  }
  return false;
};

export const getPlatformVersion = async (): Promise<number> => {
  // android and chrome
  const platform = getPlatform();
  if (
    platform === PlatformType.ANDROID &&
    ["Chrome", "Chrome Mobile"].includes(name || "")
  ) {
    const { userAgentData } = navigator as any;
    const result = await userAgentData.getHighEntropyValues([
      "platformVersion",
    ]);
    const { platformVersion } = result;
    return parseInt(platformVersion || "999");
  }

  if (os) {
    const version = parseInt(os.version || "999");
    return version;
  }
  return 999;
};

export const isLegacyPlatform = async () => {
  try {
    // do not check desktops
    if (isDesktop()) {
      return false;
    }

    const platformName = getPlatform();
    const platformVersion = await getPlatformVersion();

    // catch old ios
    if (platformName === PlatformType.APPLE) {
      return platformVersion <= 13;
    }

    // catch old android
    if (platformName === PlatformType.ANDROID) {
      return platformVersion <= 11;
    }
  } catch (err) {
    // nothing, fallbacks to false
  }

  // default case
  return false;
};
