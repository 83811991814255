import { request } from "lib/request";
import { PaddlePaymentMethod } from "models/paddle";
import { ProgramPlan } from "models/plan";

export interface PaylinkResponse {
  url: string;
  id: string; // id for the payment
}

export interface PaylinkParams {
  productId: string;
  paymentProvider: "paddle";
  returnPath: string;
  language: string;
  plan: ProgramPlan;
  paymentMethod?: PaddlePaymentMethod;
  couponCode?: string;
}

export const fetchPaylink = async (params: PaylinkParams) => {
  const response = await request<PaylinkResponse>({
    method: "POST",
    url: "/v1/pay-link",
    data: params,
  });

  try {
    const { url, id } = response.data;
    return { url, id };
  } catch (_err) {
    return { url: "", id: "" };
  }
};
