import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import MoreInfoOfContent from "./more-info-of";

export const moreInfoOfContentValidator: ValidatorMethod = (state, _t) => {
  const { moreInfoOf = [] } = state;
  const result = moreInfoOf.length >= 1 ? "pass" : "fail";
  return result;
};

export const moreInfoOfSchema: ContentSchema = {
  Component: MoreInfoOfContent,
  validator: moreInfoOfContentValidator,
};
