import { FunctionComponent, useEffect, useState } from "react";
import { useConfig } from "hooks/config";
import { clear, clearUserSelectionsFromState } from "lib/storage";
import {
  TOKEN,
  REFRESH_TOKEN,
  SIGNUP_EMAIL_KEY,
  SIGNUP_NAME_KEY,
} from "models/user";
import { QuestionnaireModel, QuestionnaireVersion } from "models/questionnaire";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { Navigate, useNavigate } from "react-router";
import { usePageLocale, useQuestionLink } from "hooks/route";
import { getControlQuestionnaireVersion } from "lib/questionnaire";

const QuestionnaireRaffleView: FunctionComponent = () => {
  const locale = usePageLocale();
  const config = useConfig();
  const {
    uuid,
    questionnaireVersion = getControlQuestionnaireVersion(locale),
  } = config;
  const navigate = useNavigate();
  const [questionnaire, toggleQuestionnaire] = useState<
    QuestionnaireModel | undefined
  >(undefined);

  const qurl = useQuestionLink(
    questionnaire ? questionnaire.id : getControlQuestionnaireVersion(locale),
    questionnaire ? questionnaire.initialQuestion : 2,
  );

  useEffect(() => {
    const fetchVersion = async () => {
      // clear some shit
      clearUserSelectionsFromState();

      // additional clearings
      clear(TOKEN);
      clear(REFRESH_TOKEN);
      clear(SIGNUP_EMAIL_KEY);
      clear(SIGNUP_NAME_KEY);

      // special handling for some variants
      if (questionnaireVersion === QuestionnaireVersion.AI) {
        navigate("https://www.masterenglish.ai");
        return;
      } else if (questionnaireVersion === QuestionnaireVersion.AIMX) {
        navigate("https://www.masterenglish.ai/mx");
        return;
      }

      // fetch questionnaire so we know initialQuestion
      const questionnaire = await loadCleanQuestionnaire(
        uuid,
        questionnaireVersion,
        false,
        locale,
      );

      toggleQuestionnaire(questionnaire);
    };

    fetchVersion();
  }, []);

  // show just empty view
  if (!questionnaire) {
    return null;
  }

  return <Navigate to={qurl} />;
};

export default QuestionnaireRaffleView;
