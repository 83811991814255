import { FunctionComponent } from "react";
import { Navigate, useParams, useSearchParams } from "react-router";

interface RedirectProps {
  to: string;
}

const Redirect: FunctionComponent<RedirectProps> = ({ to }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  let url = to;

  // inject keys
  const keys = Object.keys(params);
  for (const key of keys) {
    const value = params[key];
    url = url.replace(`:${key}`, value || "");
  }

  // inject search query
  if (searchParams.size > 0) {
    url = `${url}?${searchParams.toString()}`;
  }

  return <Navigate replace to={url} />;
};

export default Redirect;
