import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import TitleContent from "./title";

export const titleContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const titleSchema: ContentSchema = {
  Component: TitleContent,
  validator: titleContentValidator,
};
