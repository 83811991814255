import styled, { css } from "styled-components";
import devices from "theme/devices";

import { alpha, ColorCode } from "theme/colors";
import { ThemeProps } from "models/theme";
import { light, semibold } from "theme/fonts";

export const StyledTestimonial = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  background-color: ${ColorCode.LIGHT};
  border-radius: 20px;
  padding: 2rem;

  @media ${devices.tablet} {
    font-size: 1.25em;
    line-height: 1.375em;
  }

  @media ${devices.desktop} {
    font-size: 1.25em;
    line-height: 1.375em;
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      margin-left: -1rem;
      margin-right: -1rem;
      background-color: ${ColorCode.WHITE};
      box-shadow: 0 7px 70px ${alpha(ColorCode.BLACK, 0.15)};
      border-radius: 1rem;
      margin-top: 1rem;
      margin-bottom: 4rem;
    `}
`;

export const StyledContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledQuote = styled.img<ThemeProps>`
  display: inline-block;
  margin-right: auto;
  width: 40px;
  height: 30px;
  margin-bottom: 20px;
  margin-top: 10px;

  @media ${devices.tablet} {
    width: 60px;
    height: 40px;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      display: none;
    `}
`;

export const StyledText = styled.div<ThemeProps>`
  ${(props) =>
    props.$theme === "MasterEnglish" &&
    css`
      color: ${ColorCode.BLACK};
      ${light()};
      font-style: italic;

      &::before {
        content: "“";
      }

      &::after {
        content: "”";
      }
    `}
`;

export const StyledStars = styled.img`
  width: 75px;
  align-self: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const StyledDetails = styled.div`
  margin-left: auto;
  color: ${ColorCode.BLACK};
  text-transform: uppercase;
  font-size: 0.75em;
  margin-top: 1rem;
`;

export const StyledDash = styled.span``;

export const StyledName = styled.span``;

export const StyledCountry = styled.span``;

export const StyledAuthor = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const StyledAuthorPicture = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

export const StyledAuthorDetails = styled.div`
  padding-left: 1rem;
  color: ${ColorCode.BLACK};
  line-height: 1.5;
`;

export const StyledAuthorName = styled.div`
  ${semibold()}
`;

export const StyledAuthorCountry = styled.div``;

export const StyledDisclaimer = styled.div`
  font-size: 0.825em;
  margin-top: 0.825em;
`;
