import Badge from "components/badge";
import styled from "styled-components";

export const StyledBadge = styled(Badge)`
  z-index: 99;

  img {
    top: -2rem;
    right: 2.5rem;
  }
`;
