import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ImageContent from "./image";

export const imageContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const imageSchema: ContentSchema = {
  Component: ImageContent,
  validator: imageContentValidator,
};
