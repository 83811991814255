import { DaySelectionContentType } from "models/content/day-selection";
import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import DaySelectionContent from "./day-selection";

export const daySelectionContentValidator: ValidatorMethod = (
  state,
  _t,
  content,
) => {
  // find out days required, with a length fallback
  const { daySelectionOptions } = content as DaySelectionContentType;
  const daysRequired = daySelectionOptions?.days || 5;
  const { days = [] } = state;
  const result = days.length >= daysRequired ? "pass" : "fail";
  return result;
};

export const daySelectionSchema: ContentSchema = {
  Component: DaySelectionContent,
  validator: daySelectionContentValidator,
};
