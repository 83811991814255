import { FunctionComponent, useEffect } from "react";
import { ContentProps } from "../factory";
import { PreparingProgramSimpleContentType } from "models/content/preparing-program-simple";
import {
  StyledDescription,
  StyledPreparingProgram,
  StyledSpinner,
  StyledTitle,
} from "./preparing-program-simple.styles";
import { MediaSchema } from "lib/media";

interface PreparingProgramSimpleContentProps extends ContentProps {
  content: PreparingProgramSimpleContentType;
}

const PreparingProgramSimpleContent: FunctionComponent<
  PreparingProgramSimpleContentProps
> = ({ onSubmit, content }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onSubmit();
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  const { preparingProgramSimpleOptions } = content;
  const { title, text } = preparingProgramSimpleOptions;

  const filename = `chat/Chat_Spinner`;
  const schema: MediaSchema = {
    filename,
    extension: "png",
  };

  return (
    <StyledPreparingProgram>
      <StyledSpinner schema={schema} />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{text}</StyledDescription>
    </StyledPreparingProgram>
  );
};

export default PreparingProgramSimpleContent;
