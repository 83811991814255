import { CheckoutEventNames, initializePaddle } from "@paddle/paddle-js";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { handleCheckoutPaymentSelectedEvent } from "lib/paddle";
import { getProgramPlan } from "lib/study-schedule";
import { ProductModel } from "models/product";
import { FunctionComponent, useEffect } from "react";

const environment =
  import.meta.env.VITE_PUBLIC_PADDLE_SANDBOX === "true"
    ? "sandbox"
    : "production";
const token = import.meta.env.VITE_PUBLIC_PADDLE_BILLING_TOKEN || "";

interface PaddleBillingProps {
  product: ProductModel;
  onLoad: () => void;
}

const PaddleBllingSetup: FunctionComponent<PaddleBillingProps> = ({
  onLoad,
  product,
}) => {
  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const state = useQuestionnaireState();
  const programPlan = getProgramPlan(questionnaire, state);

  useEffect(() => {
    console.info(`environment = ${environment}`);
    initializePaddle({
      environment,
      token,
      eventCallback: function (data) {
        console.info(data);

        // Paddle react component will add delegatePaddleEvent method to handle events in a proper scope
        switch (data.name) {
          case CheckoutEventNames.CHECKOUT_PAYMENT_SELECTED:
            handleCheckoutPaymentSelectedEvent(config, programPlan, product);
            break;
        }
      },
    }).then(() => {
      onLoad();
    });
  }, []);

  return null;
};

export default PaddleBllingSetup;
