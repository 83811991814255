import Image from "components/image";
import { BubbleContentType } from "models/content/bubble";
import { FunctionComponent } from "react";
import { ContentProps } from "../factory";
import {
  ImageCSS,
  parseBubbleImageSchema,
  StyledWrapper,
} from "./bubble.styles";

interface BubbleContentProps extends ContentProps {
  content: BubbleContentType;
}

const BubbleContent: FunctionComponent<BubbleContentProps> = ({ content }) => {
  const { bubbleOptions } = content;

  const schema = parseBubbleImageSchema(bubbleOptions.filename);
  return (
    <StyledWrapper>
      <Image schema={schema} alt={bubbleOptions.filename} css={ImageCSS} />
    </StyledWrapper>
  );
};

export default BubbleContent;
