import { FunctionComponent } from "react";
import { StyledImage, StyledWrapper } from "./start-here.styles";
import { useMedia } from "hooks/media";

const StartHere: FunctionComponent = () => {
  const src = useMedia("start-here/StartHere", "mobile", "png", true);
  return (
    <StyledWrapper>
      <StyledImage src={src} />
    </StyledWrapper>
  );
};

export default StartHere;
