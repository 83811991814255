import { saveAs } from "file-saver";
import definititions from "./definitions";
import { LocalePathMap, Locales, LocaleType } from "models/locale";

export const downloadRobotsTXT = () => {
  // gather a list of urls to block
  const allowList: string[] = [];
  definititions.forEach((route) => {
    const { indexable = false, pathSets } = route;

    if (indexable) {
      for (const uncastedLocale in pathSets) {
        const locale = uncastedLocale as LocaleType;

        // ignore non supported locales
        if (Locales.includes(locale) === false) {
          continue;
        }

        const localePath = LocalePathMap[locale];
        const set = pathSets[locale];
        if (set) {
          let { path } = set;
          if (path !== "") {
            path = `${path}/`;
          }
          const pathToAllow = `${localePath}/${path}`;

          // accepted, push
          allowList.push(pathToAllow);

          // if spanish, add rooted route
          if (locale === "es-419") {
            allowList.push(path);
          }
        }
      }
    }
  });

  // file contents
  const contents = [
    "User-agent: *\n",

    // disallow everything by default
    "Disallow: /\n",

    // add allowed resources (NOTICE: ends with $)
    ...allowList.map((path) => `Allow: /${path}$\n`),

    // do not block  styles or images
    "Allow: /*.css$\n",
    "Allow: /*.js$\n",
    "Allow: /*.png$\n",
    "Allow: /*.jpg$\n",
    "Allow: /*.wepb$\n",
    "Allow: /sitemap*.xml$\n",
    "Allow: /robots.txt$\n",

    // sitemap reference
    `Sitemap: https://www.stage.masterenglish.com/sitemap.xml`,
  ];

  // save to file
  saveAs(
    new Blob(contents, {
      type: "text/plain;charset=utf-8",
    }),
    "robots.txt",
  );
};
