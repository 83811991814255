import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import SkillsToImproveContent from "./skills-to-improve";

export const skillsToImproveContentValidator: ValidatorMethod = (state, _t) => {
  const { skillsToImprove = [] } = state;
  const result = skillsToImprove.length >= 1 ? "pass" : "fail";
  return result;
};

export const skillsToImproveSchema: ContentSchema = {
  Component: SkillsToImproveContent,
  validator: skillsToImproveContentValidator,
};
