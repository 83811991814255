import { ProductType } from "models/product";
import { QuestionnaireVersion } from "models/questionnaire";
import { Currency } from "./currency";
import { isWindow } from "./env";

// implementation copied from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
const shaEmail = async (email: string) => {
  // NOTE: this might not be used in GTM as GTM parses the email values from HTML DOM
  const emailUint8 = new TextEncoder().encode(email);
  const hashBuffer = await crypto.subtle.digest("SHA-256", emailUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

const gtmEvent = (eventName: string, eventDetails?: unknown) => {
  if (isWindow()) {
    (window as any).dataLayer.push({
      event: eventName,
      details: eventDetails,
    });
  }
};

export const gtmUserId = (uuid: string) => {
  gtmEvent("user_id", {
    uuid,
  });
};

export const gtmSignUp = async (email: string) => {
  gtmEvent("me_sign_up", {
    sha256_email_address: await shaEmail(email),
    event_label: "registration",
  });
};

// pure js exceptions
export const gtmJSException = (
  error: any,
  userAgent: string,
  stack?: string,
  componentStack?: string,
) => {
  const { message } = error;
  gtmEvent("js-exception", { message, stack, componentStack, userAgent });
};

// to track endpoint originated issues
export const gtmException = (
  status_code: number,
  endpoint: string,
  message: string,
) => {
  gtmEvent("exception", { status_code, endpoint, message });
};

export const gtmRequest = (response_code: number, endpoint: string) => {
  gtmEvent("me_web_request", {
    response_code,
    endpoint,
  });
};

export const gtmRequestDuration = (
  request_duration: number,
  response_code: number,
  endpoint: string,
) => {
  gtmEvent("me_web_request_duration", {
    request_duration,
    response_code,
    endpoint,
  });
};

export const gtmQuestionnaireStart = async () => {
  gtmEvent("me_web_questionnaire_start");
};

export const gtmQuestionnaireDone = async () => {
  gtmEvent("me_web_questionnaire_done");
};

export const gtmPurchaseFail = async () => {
  gtmEvent("me_web_purchase_fail");
};

export const gtmPurchaseDone = async (
  email: string,
  productType: ProductType,
  productPrice: number,
  currency: Currency,
  version: QuestionnaireVersion,
) => {
  gtmEvent("me_web_purchase_done", {
    sha256_email_address: await shaEmail(email),
    productType,
    productPrice,
    currency,
    version,
  });
};

export const gtmSubcription = async (
  email: string,
  productType: ProductType,
  productPrice: number,
  currency: Currency,
  version: QuestionnaireVersion,
) => {
  gtmEvent("me_web_subscription", {
    sha256_email_address: await shaEmail(email),
    productType,
    productPrice,
    currency,
    version,
  });
};

export const gtmHomepage = () => {
  gtmEvent("me_web_homepage");
};

export const gtmOneTrust = () => {
  gtmEvent("me_web_onetrust");
};
