import { ValidatorMethod } from "../../validations";
import { ContentSchema } from "../factory";
import ParagraphsContent from "./paragraphs";

export const paragraphsContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const paragraphsSchema: ContentSchema = {
  Component: ParagraphsContent,
  validator: paragraphsContentValidator,
};
